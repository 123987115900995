import * as React from 'react';
import { getFormattedPrice } from '../utils/formattedPrice'
import styles from './Receipt.module.css'
import { Customization, OrderItem, Receipt as ReceiptT } from "../types/Receipt"

interface ReceiptProps {
  receipt: ReceiptT
}

function calculateTax (taxCode: number, price: number) {
  let taxFactor;

  switch (taxCode) {
    case 1:
      taxFactor = 0.8;
      break;
    case 2:
      taxFactor = 1 / 1.12;
      break;
    case 3:
      taxFactor = 1 / 1.06;
      break;
    default:
      taxFactor = 0.8;
  }
  return {
    total: price,
    priceExVAT: price * taxFactor,
    VAT: price - price * taxFactor,
  };
}

function Receipt ({ receipt }: ReceiptProps) {
  const {
    order,
    name,
    address,
    postalCode,
    city,
    businessRegistrationNumber,
    phone,
  } = receipt;
  const {
    createdAt,
    loopNumber,
    orderNo,
    orderItems,
    paymentInformation,
    totalPrice,
  } = order || {};
  const { paymentId } = paymentInformation || {}

  function groupBy (items: OrderItem[], key: string) {
    if (items) {
      return items.reduce((sum: any, current: any) => {
        const total = sum[current[key]] || 0
        return {
          ...sum,
          [current[key]]: total + current.totalPrice,
        };
      }, {})
    }
  }

  const taxItems = groupBy(orderItems, 'taxCode');
  const tax25 =
    taxItems && taxItems.hasOwnProperty('1')
      ? calculateTax(1, taxItems['1'])
      : null;
  const tax12 =
    taxItems && taxItems.hasOwnProperty('2')
      ? calculateTax(2, taxItems['2'])
      : null;
  const tax6 =
    taxItems && taxItems.hasOwnProperty('3')
      ? calculateTax(3, taxItems['3'])
      : null;

  const exVAT25 = tax25?.priceExVAT || 0
  const exVAT12 = tax12?.priceExVAT || 0
  const exVAT6 = tax6?.priceExVAT || 0

  const totalExVAT = exVAT25 + exVAT12 + exVAT6

  function formatDate (dateFrom: string) {
    const date = new Date(dateFrom)
    const year = date.getFullYear()
    let month = `${date.getMonth() + 1}`
    let day = `${date.getDate()}`
    let hours = `${date.getHours()}`
    let minutes = `${date.getMinutes()}`

    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + month
    }
    if (hours.length < 2) {
      hours = '0' + hours
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes
    }

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  return (
    <>
      <h1 className={styles.receiptHeading}>Kvitto</h1>
      <div className={styles.receiptOrderNo}>
        {loopNumber ? loopNumber : orderNo}
      </div>
      <div className={styles.products}>
        {orderItems &&
          orderItems.map((item: OrderItem, index: number) => (
            <div className={styles.productRow} key={index}>
              <div className={styles.product}>
                <span className={styles.productName}>{item.quantity} st {item.displayName}</span>
                <span>{getFormattedPrice(item.unitPrice / 100 * item.quantity)}</span>
              </div>
              {item.customizationMenuItems.map((ingredient: Customization, i: number) => {
                if (!ingredient.preSelected || ingredient.price !== 0) {
                  return (
                    <div className={styles.extraIngredient} key={i}>
                      <span className={styles.productName}>
                        {ingredient.customizationType === 1
                          ? '+'
                          : ingredient.customizationType === 2
                            ? '-'
                            : ''}{' '}
                        {ingredient.name}
                      </span>{' '}
                      <span>{getFormattedPrice(ingredient.price / 100 * item.quantity)}</span>
                    </div>
                  )
                }
              }

              )}
            </div>
          ))}
      </div>
      <div className={styles.summary}>
        <div className={styles.summaryRow}>
          <span>Netto</span>
          <span>{getFormattedPrice(totalExVAT / 100)}</span>
        </div>
        {tax25 && (
          <div className={styles.summaryRow}>
            <span>Moms (25%)</span>
            <span>{getFormattedPrice(tax25.VAT / 100)}</span>
          </div>
        )}
        {tax12 && (
          <div className={styles.summaryRow}>
            <span>Moms (12%)</span>
            <span>{getFormattedPrice(tax12.VAT / 100)}</span>
          </div>
        )}
        {tax6 && (
          <div className={styles.summaryRow}>
            <span>Moms (6%)</span>
            <span>{getFormattedPrice(tax6.VAT / 100)}</span>
          </div>
        )}
        <div className={styles.total}>
          <span>Total</span> <span>{getFormattedPrice(totalPrice / 100)}</span>
        </div>
      </div>
      <div className={styles.storeInfo}>
        <h2 className={styles.storeName}>{name}</h2>
        <p className={styles.addressRow}>{address}</p>
        <p className={styles.addressRow}>{`${postalCode} ${city}`}</p>
        {phone && <p className={styles.addressRow}>Tel.nr: {phone}</p>}
        <p className={styles.addressRow}>Orgnr: {businessRegistrationNumber}</p>
        <p className={styles.addressRow}>Datum: {formatDate(createdAt)}</p>
        <p className={styles.addressRow}>Ref: {paymentId}</p>
      </div>
      <p className={styles.thanks}>TACK!</p>
    </>
  );
}
export default Receipt
