import * as React from 'react'
import { connect } from 'react-redux'
import { handleOpeningHours } from '../../../utils/openingHours'
import type { RootState, Dispatch } from '../../../app/store'
import type { MenuT, MealT, AddOnT } from '../../../models/menus'
import Page from '../../layout/Page'
import AddOns from '../../menus/AddOns'
import ModalAddOns from '../../menus/ModalAddOns'
import ClosedBanner from '../../ClosedBanner'
import Customizations from '../../Customizations'
import Modal from '../../Modal'
import Angle from '../../../icons/Angle'
import styles from './Product.module.css'
import QuantityButton from '../../QuantityButton'
import { useNavigate, useParams } from 'react-router'


interface ProductT {
  menus: Array<MenuT>,
  product?: MealT | null
  addOns?: Array<AddOnT>
  fetchProduct: (payload: { menuId: string, productId: string }) => void
  addItemToCart: (payload: { storeId: string, productId: string, itemType: number, customizationItems: Array<string>, amount: number }) => void,
  updateProduct: (payload: MealT | null) => void
}

interface CustomizationMenuItem {
  id: string;
  customizationType: number;
  name: string;
  active: boolean;
  price: number;
  preSelected: boolean;
}
interface SelectedItem extends CustomizationMenuItem {
  selected: boolean;
}

interface Customization {
  id: string;
  name: string;
  active: boolean;
  customizationType: number;
  customizationMenuItems: CustomizationMenuItem[];
  items: SelectedItem[];
}

function Product ({ menus, product, addOns, updateProduct, fetchProduct, addItemToCart }: ProductT) {
  const [addonModalIsActive, setAddonModalIsActive] = React.useState(false)
  const [contentsIsOpen, setContentsIsOpen] = React.useState(false)
  const [currentMenu, setCurrentMenu] = React.useState<MenuT | null>(null)
  const [customizationsToChange, setCustomizationsToChange] = React.useState<Array<Customization>>([])
  const [customizationsToAdd, setCustomizationsToAdd] = React.useState<Array<{
    id: string,
    name: string,
    price: number,
  }>>([])
  const [customizationsToRemove, setCustomizationsToRemove] = React.useState<Array<{
    id: string,
    name: string,
  }>>([])
  const [customizationsPrice, setCustomizationsPrice] = React.useState(0)
  const { name, description, price, nutrientFacts, image, customizations } = product || {}
  const [quantity, setQuantity] = React.useState(1)
  const { storeId, restaurantId, menuId, productId } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    menuId && productId &&
      fetchProduct({
        menuId,
        productId
      })
  }, [fetchProduct, menuId, productId])

  React.useEffect(() => {
    const menu = menus.find((item: MenuT) => item.id === restaurantId)
    if (menu) {
      setCurrentMenu(menu)
    }
  }, [menus, restaurantId])

  React.useEffect(() => {
    if (customizations?.customizable?.length > 0) {
      setCustomizationsToChange(customizations.customizable.map((group: Customization) => {
        return {
          ...group,
          items: group.customizationMenuItems.map((item) => {
            return {
              ...item,
              selected: item.preSelected || false
            }
          }
          )
        }
      }))

    }
  }, [customizations?.customizable])

  React.useEffect(() => {
    if (customizationsToChange.length > 0 || customizationsToAdd.length > 0) {
      const selectionsPrice = customizationsToChange.reduce((prev, current) => {
        const selectedItem = current.items?.find((item) => item.selected === true)
        if (selectedItem) {
          return prev + selectedItem.price
        } else {
          return prev
        }
      }, 0)
      const addedPrice = customizationsToAdd.reduce((prev, current) => {
        let currentPrice = current.price || 0
        return prev + currentPrice
      }, 0)
      setCustomizationsPrice(selectionsPrice + addedPrice)
    }
  }, [customizationsToChange, customizationsToAdd])

  function onAddProduct () {

    const selections = customizationsToChange.reduce<string[]>((prev, current) => {
      const selectedItem = current.items?.find((item) => item.selected === true)
      if (selectedItem) {
        return [...prev, selectedItem.id]
      } else {
        return [...prev]
      }
    }, [])

    const customizationItems: Array<string> = [...customizationsToAdd.map(ca => ca.id), ...customizationsToRemove.map(cr => cr.id), ...selections]
    storeId && productId &&
      addItemToCart({
        storeId,
        productId,
        itemType: 100,
        customizationItems,
        amount: quantity
      })
    clearSelectedIngredients()
    if (addOns && addOns.length > 0) {
      setAddonModalIsActive(true)
    } else {
      onContinue()
    }
  }

  function clearSelectedIngredients () {
    if (product) {
      const updatedProduct = { ...product }
      updateProduct(updatedProduct)
    }
  }

  function getFormattedPrice (value: number) {
    if (value === 0) {
      return '0 kr'
    }

    if (value % 1 !== 0) {
      return `${value.toFixed(2)} kr`
    }
    return `${value} kr`
  }

  function onContinue () {
    navigate(`/butik/${storeId}/restaurant/${restaurantId}`)
  }

  let isOpen: boolean = true;
  let openingDetails: { opening?: string, closes?: string } = {}

  if (currentMenu) {
    const openingHours = handleOpeningHours(currentMenu?.openingHours) || {}
    isOpen = openingHours.isOpen
    openingDetails = openingHours.openingDetails
  }

  function getTotalPrice () {
    let productPrice = price || 0
    let customPrice = customizationsPrice || 0

    return productPrice + customPrice
  }

  return (
    <Page withTopNav={true} withBackButton={true} withCancelButton={true} withCart={true}>
      <div className={styles.product}>
        {currentMenu?.name && (
          <p className={styles.menuHeading}>{currentMenu?.name}</p>
        )}
        {currentMenu && !isOpen && (
          <ClosedBanner openingTime={openingDetails.opening || ""} />
        )}
        <div className={image ? styles.contentWithImage : styles.content}>
          <div className={styles.productContent}>
            <div className={styles.productDetails}>
              {image ? (
                <div className={styles.prodctImage}>
                  <img className={styles.image} src={image} alt={name} />
                </div>
              ) : <></>}
              <div className={styles.productInfo}>
                <h1 className={styles.heading}>{name}</h1>
                <p className={styles.description}>{description}</p>
                {nutrientFacts && (
                  <button onClick={() => setContentsIsOpen(prev => !prev)} type="button" className={styles.toggleButton}>
                    <span>Innehåll och näringsvärde</span>
                    <Angle direction="down" color="#cf2e05" className={contentsIsOpen ? styles.activeToggleIcon : styles.toggleIcon} />
                  </button>
                )}
                {contentsIsOpen && (
                  <p className={styles.nutrientFacts}>{nutrientFacts}</p>
                )}
              </div>
            </div>
            <div className={styles.customizations}>
              <Customizations itemsToChange={customizationsToChange} updateItemsToChange={setCustomizationsToChange} customizations={customizations} itemsToAdd={customizationsToAdd} updateItemsToAdd={setCustomizationsToAdd} itemsToRemove={customizationsToRemove} updateItemsToRemove={setCustomizationsToRemove} />
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.actions}>
              <span className={styles.price}>{getFormattedPrice(getTotalPrice() * quantity)}</span>
              <QuantityButton quantity={quantity} onDecrease={() => setQuantity(prev => prev > 1 ? prev - 1 : prev)} onIncrease={() => setQuantity(prev => prev + 1)} type='cart' />
              <button disabled={currentMenu && isOpen === false ? true : false} className={styles.addButton} onClick={onAddProduct} type="button">Lägg till</button>
            </div>
          </div>
        </div>
        <AddOns storeId={storeId} restaurantId={restaurantId} />
      </div>
      <Modal active={addonModalIsActive} maxWidth="1440px">
        <ModalAddOns onContinue={onContinue} storeId={storeId} restaurantId={restaurantId} />
      </Modal>
    </Page>
  )
}


function mapState (state: RootState) {
  return {
    product: state.menus.currentProduct,
    menus: state.menus.items,
    addOns: state.menus.addOns,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchProduct: dispatch.menus.fetchProduct,
    addItemToCart: dispatch.cart.addItemToCart,
    updateProduct: dispatch.menus.setProduct,
  };
}


export default connect(mapState, mapDispatch)(Product)