import { createModel } from "@rematch/core";
import { IDLE, FETCHING } from "./constants";
import { fetchFrom } from '../utils/fetchData'
import { handleOpeningHours } from '../utils/openingHours'
import type { MenuT } from './menus'

interface StateT {
  status: StatusT,
  current: StoreT | null,
  stores: Array<{
    name: string,
    id: string,
  }>
}

export type StatusT = "IDLE" | "FETCHING" | "ERROR"

export type StoreT = {
  id: string,
  storeId?: string,
  name: string,
  description?: string,
  openingHours?: Array<OpeningHoursT>
  logo?: string,
  alternateLogo?: string,
  backgroundUrl?: string | null
  deliveryProviders: Array<{
    id: string,
    imageUrl: string,
    displayName: string,
    isOpen: boolean,
    deliveryType: number,
    openHours: Array<OpeningHoursT>
  }>,
  startupMessage?: {
    title?: string,
    text?: string,
  }
}

type OpeningHoursT = {
  day: number,
  open: string,
  close: string,
  specialDay: boolean,
  extraInfo?: string,
}

export const store = createModel()({
  state: {
    status: IDLE,
    storeId: null,
    current: null,
    stores: [],
  } as StateT,
  reducers: {
    startFetching (state, payload) {
      return { ...state, status: FETCHING };
    },
    setStore (state, payload: StoreT) {
      return { ...state, status: IDLE, current: payload };
    },
    setStores (state, payload: Array<{ name: string, id: string }>) {
      return { ...state, status: IDLE, stores: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchStore (payload, state) {
      const id = payload
      try {
        dispatch.store.startFetching()
        const store = await fetchFrom(`/kiosk/${id}`)

        const { storeId, name, description, openHours, logoUrl, alternateLogoUrl, restaurants, deliveryProviders, startupMessage, backgroundUrl } = store

        dispatch.store.setStore({
          id: storeId,
          name,
          description,
          openingHours: openHours,
          logo: logoUrl,
          alternateLogo: alternateLogoUrl,
          deliveryProviders,
          startupMessage,
          backgroundUrl
        })
        if (restaurants && restaurants.length > 0) {
          const menus = restaurants.sort((a: any, b: any) => {
            const first = a.orderingNumber ? a.orderingNumber : 10000
            const second = b.orderingNumber ? b.orderingNumber : 10000
            return first - second
          }).map(({ id, name, description, imageUrl, openHours, restaurantMessage }: MenuT & {
            openHours: Array<OpeningHoursT>
            sortOrder?: number,
            imageUrl?: string,
          }) => {
            return {
              id,
              name,
              description,
              image: imageUrl,
              openingHours: openHours,
              restaurantMessage
            }
          }).sort((a: any, b: any) => {
            const aOpeningHours = handleOpeningHours(a.openingHours)
            const bOpeningHours = handleOpeningHours(b.openingHours)
            const first = aOpeningHours.isOpen ? 1 : 0
            const second = bOpeningHours.isOpen ? 1 : 0
            return second - first
          })
          dispatch.menus.setMenuItems(menus)
        } else {
          dispatch.menus.setMenuItems([])
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchAllStores (payload, state) {
      try {
        dispatch.store.startFetching()
        const stores = await fetchFrom(`/kiosk`)
        const storeItems = stores.map(({ storeId, name }: { storeId: string, name: string }) => ({
          id: storeId,
          name: name,
        }))
        if (storeItems && storeItems.length > 0) {
          dispatch.store.setStores(storeItems)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchStoreFromBms (bms, state) {
      try {
        dispatch.store.startFetching()
        const store = await fetchFrom(`/kiosk/bms/${bms}`)
        if (store) {
          dispatch.store.setStore(store)
        }
      } catch (error) {
        console.log(error)
        dispatch.store.setStoreId(null)
      }
    }
  }),
});
