import * as React from 'react'
import { connect } from 'react-redux'
import { handleOpeningHours } from '../../../utils/openingHours'
import type { RootState } from '../../../app/store'
import type { MenuT, StatusT } from '../../../models/menus'
import Page from '../../layout/Page'
import Loading from '../../Loading'
import MenuItem from '../../menus/item'
import styles from './Menus.module.css'
import { useLocation } from 'react-router'


interface MenusT {
  status: StatusT,
  menuItems: Array<MenuT>
}

function Menus ({ status, menuItems }: MenusT) {
  const location = useLocation()
  return (
    <Page withTopNav={true} withCancelButton={true} withCart={true}>
      {status === "FETCHING" && (
        <Loading heading="Laddar menyer" />
      )}
      <div className={styles.content}>
        <h1 className={styles.heading}>Alla menyer {menuItems && menuItems.length > 5 && (
          <span>({menuItems.length.toString()})</span>
        )}</h1>
        <ul className={styles.list}>
          {menuItems?.map(({ id, name, description, image, openingHours, restaurantMessage }) => {
            const { isOpen, openingDetails } = handleOpeningHours(openingHours)
            return (
              <MenuItem key={id} href={`${location.pathname}/${id}`} name={name} description={description} image={image} isOpen={isOpen} openingDetails={openingDetails} message={restaurantMessage} />
            )
          })}
        </ul>
      </div>
    </Page>
  )
}

function mapState (state: RootState) {
  return {
    status: state.menus.status,
    menuItems: state.menus.items,
  };
}


export default connect(mapState, null)(Menus)