import * as React from 'react';
import { connect } from 'react-redux';
import type { RootState, Dispatch } from '../../../app/store';
import Header from '../../layout/Header';
import MessageBanner from '../../MessageBanner';
import Receipt from '../../Receipt';
import styles from './Confirmation.module.css';
import { useNavigate, useParams } from 'react-router';

interface ConfirmationT {
  getReceipt: (payload: any) => void;
  receipt: any;
  resetCart: () => void;
}

function Confirmation ({
  receipt,
  getReceipt,
  resetCart
}: ConfirmationT) {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const { orderNo, loopNumber } = receipt?.order || {};
  const { checkoutMessage } = receipt || {};

  React.useEffect(() => {
    if (window && loopNumber) {
      window.print();
    }
  }, [loopNumber]);

  React.useEffect(() => {
    if (loopNumber) {
      setTimeout(() => {
        resetCart();
        navigate(`/butik/${storeId}`);
      }, 30000);
    }
  }, [loopNumber, resetCart, navigate, storeId]);

  React.useEffect(() => {
    if (storeId) {
      getReceipt({ storeId });
    }
  }, [storeId, getReceipt]);

  return (
    <>
      <div className={styles.page}>
        <Header withCancelButton={false} />
        <div className={styles.pageContent}>
          <section className={styles.checkout}>
            <div className={styles.content}>
              <h1 className={styles.heading}>Tack för ditt köp!</h1>
              <p className={styles.preamble}>
                Köket påbörjar nu din beställning
              </p>
              <div className={styles.orderId}>
                {loopNumber ? loopNumber : orderNo}
              </div>
              <MessageBanner
                title={'Ditt kvitto och ordernummer skrivs nu ut'}
                description={checkoutMessage?.text || ''}
                maxWidth="760px"
              />
            </div>
          </section>
        </div>
      </div>
      <div className={styles.printReceipt}>
        {receipt && <Receipt receipt={receipt} />}
      </div>
    </>
  );
}

function mapState (state: RootState) {
  return {
    receipt: state.order.receipt,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    getReceipt: dispatch.order.getReceipt,
    resetCart: () => dispatch({ type: 'RESET_CART' }),
  };
}

export default connect(mapState, mapDispatch)(Confirmation);
