import * as React from 'react';
import { connect } from 'react-redux';
import QRCode from 'react-qr-code';
import type { RootState, Dispatch } from '../../../app/store';
import Page from '../../layout/Page';
import Modal from '../../Modal';
import styles from './QR.module.css';
import { useNavigate, useParams } from 'react-router';

function QR ({
  paymentUrl,
  currentOrderId,
  cartId,
  paymentStatus,
  getPaymentStatus,
  setPaymentStatus,
  createOrder,
}: any) {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [paymentRefLink, setPaymentRefLink] = React.useState<string | null>(
    null
  );
  const [activePolling, setActivePolling] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState(false);

  React.useEffect(() => {
    if (paymentUrl && currentOrderId) {
      const paymentRef = encodeURIComponent(btoa(paymentUrl));
      const baseUrl =
        process.env.REACT_APP_ENV === 'production'
          ? 'https://kiosk.icapronto.se'
          : process.env.REACT_APP_ENV === 'stage'
            ? 'https://kiosk-staging.icapronto.se'
            : 'http://localhost:3000';
      const refLink = `${baseUrl}/butik/${storeId}/betala/${currentOrderId}?ref=${paymentRef}`;

      setPaymentRefLink(refLink);
      setActivePolling(true);
    }
  }, [paymentUrl, currentOrderId, storeId]);

  React.useEffect(() => {
    let interval: any;
    if (activePolling === true && (paymentStatus < 101 || paymentStatus === 500)) {
      interval = setInterval(() => {
        getPaymentStatus({
          storeId,
        });
      }, 1000);
    } else if (paymentStatus > 199 && paymentStatus !== 500) {
      setActivePolling(false);
    }
    return () => clearInterval(interval);
  }, [activePolling, getPaymentStatus, storeId, paymentStatus]);

  React.useEffect(() => {
    if (activePolling === false && paymentStatus === 200) {
      navigate(`/butik/${storeId}/tack`);
    } else if (
      activePolling === false &&
      (paymentStatus === 300 || paymentStatus === 400)
    ) {
      setPaymentError(true);
      setPaymentStatus(0);
    }
  }, [activePolling, paymentStatus, setPaymentStatus, storeId, navigate]);

  function retryPayment () {
    setPaymentError(false);
    createOrder();
  }

  return (
    <Page withCancelButton={true}>
      <section className={styles.qr}>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            Scanna QR-koden med din mobilkamera
          </h1>
          {paymentRefLink ? (
            <div>
              {process.env.REACT_APP_ENV !== 'production' ? (
                <a target="_blank" rel="noreferrer" href={paymentRefLink}>
                  <QRCode value={paymentRefLink} />
                </a>
              ) : (
                <QRCode value={paymentRefLink} />
              )}
            </div>
          ) : (
            <div>Laddar…</div>
          )}
        </div>
      </section>
      <Modal
        maxWidth="850px"
        active={paymentError}
        onConfirm={() => null}
        onCancel={() => retryPayment()}
      >
        <div className={styles.modalContent}>
          <h2 className={styles.modalHeading}>Betalningen misslyckades</h2>
          <button
            onClick={() => retryPayment()}
            className={styles.modalButton}
            type="button"
          >
            Försök igen
          </button>
        </div>
      </Modal>
    </Page>
  );
}

function mapState (state: RootState) {
  return {
    paymentUrl: state.order.paymentUrl,
    currentOrderId: state.order.currentOrderId,
    cartId: state.cart.cartId,
    paymentStatus: state.order.paymentStatus,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    getPaymentStatus: dispatch.order.getPaymentStatus,
    createOrder: dispatch.order.createOrder,
    setPaymentStatus: dispatch.order.setPaymentStatus,
  };
}

export default connect(mapState, mapDispatch)(QR);
