import * as React from 'react'
import Erase from '../icons/Erase'
import styles from './Keyboard.module.css'

type KeyboardT = {
  onClick: (key: number) => void
}

function Keyboard({ onClick }: KeyboardT) {
  return (
    <div>
      <button className={styles.key} onClick={() => onClick(1)} type="button">1</button>
      <button className={styles.key} onClick={() => onClick(2)} type="button">2</button>
      <button className={styles.key} onClick={() => onClick(3)} type="button">3</button>
      <button className={styles.key} onClick={() => onClick(4)} type="button">4</button>
      <button className={styles.key} onClick={() => onClick(5)} type="button">5</button>
      <button className={styles.key} onClick={() => onClick(6)} type="button">6</button>
      <button className={styles.key} onClick={() => onClick(7)} type="button">7</button>
      <button className={styles.key} onClick={() => onClick(8)} type="button">8</button>
      <button className={styles.key} onClick={() => onClick(9)} type="button">9</button>
      <button className={styles.key} onClick={() => onClick(0)} type="button">0</button>
      <button className={styles.key} onClick={() => onClick(-1)} type="button">
        <Erase className={styles.eraseIcon} color="#cf2e05" />
      </button>
    </div>
  )
}

export default Keyboard