import * as React from 'react'
import { connect } from 'react-redux'
import type { RootState, Dispatch } from '../../app/store'
import { getFormattedPrice } from '../../utils/formattedPrice'
import type { AddOnT } from '../../models/menus'
import styles from './AddOns.module.css'

type AddOnsT = {
  storeId: string,
  restaurantId: string,
  addOns: Array<AddOnT>
  fetchAddOns: (restaurantId: string) => void
  addItemToCart: (payload: { storeId: string, productId: string, categoryId: string, itemType: number}) => void,
}

function AddOns({ addOns, fetchAddOns, addItemToCart, storeId, restaurantId }: AddOnsT) {

  React.useEffect(() => {
    fetchAddOns(restaurantId)
  }, [fetchAddOns, restaurantId])

  function onAddItem(productId: string, categoryId: string, itemType: number) {
    addItemToCart({
      storeId,
      productId,
      categoryId,
      itemType
    })
  }

  return addOns && addOns.length > 0 ? (
    <div className={styles.addons}>
      <h2 className={styles.heading}>Lägg till</h2>
      {addOns.map(({ id, Name, products }) => (
        <div className={styles.group} key={id}>
          <h3 className={styles.groupHeading}>{Name}</h3>
          <ul className={styles.list}>
            {products.map(({ id, imageUrl, itemType, groceryDisplayName, price, categoryId }) => (
              <li className={styles.item} key={id}>
                <div className={styles.itemContent}>
                  <div className={styles.info}>
                    <img className={styles.image} src={imageUrl} alt={groceryDisplayName} />
                    <p className={styles.name}>{groceryDisplayName}</p>
                  </div>
                  <div className={styles.summary}>
                    <span className={styles.price}>{getFormattedPrice(price || 0)}</span>
                    <button onClick={() => onAddItem(id, categoryId, itemType)} className={styles.addButton} type="button">Lägg till</button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  ) : null
}

function mapState(state: RootState) {
  return {
    addOns: state.menus.addOns,
  };
}
function mapDispatch(dispatch: Dispatch) {
  return {
    fetchAddOns: dispatch.menus.fetchAddOns,
    addItemToCart: dispatch.cart.addItemToCart,
  };
}

export default connect(mapState, mapDispatch)(AddOns)