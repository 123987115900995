import { createModel } from "@rematch/core";
import { IDLE, FETCHING } from "./constants";
import { fetchFrom } from '../utils/fetchData'

interface StateT {
  status: StatusT,
  personalNumber: string
  bonusStatus: BonusStatusT
}

export type StatusT = "IDLE" | "FETCHING" | "ERROR"
export type BonusStatusT = "REGISTERED" | "FAILED" | "IDLE"

export const customer = createModel()({
  state: {
    status: "IDLE",
    bonusStatus: "IDLE",
    personalNumber: "",
  } as StateT,
  reducers: {
    startFetching(state, payload) {
      return { ...state, status: FETCHING };
    },
    setPersonalNumber(state, payload: string) {
      return { ...state, status: IDLE, personalNumber: payload };
    },
    setBonusStatus(state, payload: BonusStatusT) {
      return { ...state, status: IDLE, bonusStatus: payload };
    },
  },
  effects: (dispatch) => ({
    async addBonus(payload, state) {
      const { storeId, id, orderId } = payload
      try {
        dispatch.customer.startFetching()
        const { icaHouseHoldId } = await fetchFrom(`/order/${orderId}/${storeId}/bonus`, {
          method: 'PUT',
          body: {
            ssn: id
          }
        })
        if(icaHouseHoldId) {
          dispatch.customer.setBonusStatus('REGISTERED')
        } else {
          dispatch.customer.setBonusStatus('FAILED')
        }
      } catch (error) {
        console.log(error)
        dispatch.customer.setBonusStatus('FAILED')
      }
    },
  }),
});
