import * as React from 'react'
import { Link } from 'react-router-dom'
import type { MealT } from '../../models/menus'
import { getFormattedPrice } from '../../utils/formattedPrice'
import Arrow from '../../icons/Arrow'
import styles from './MealItem.module.css'

type PropsT = {
  meal: MealT,
  href: string,
}

function MealItem ({ href, meal }: PropsT) {
  const { name, description, price, image } = meal

  return (
    <li className={styles.item}>
      <Link className={styles.itemContent} to={href}>
        <>
          {image && (
            <div className={styles.imageContainer}>
              <img className={styles.image} src={image} alt={`Produktbild ${name}`} />
            </div>
          )}
          <div className={styles.text}>
            <h3 className={styles.heading}>{name}</h3>
            <p className={styles.description}>{description}</p>
          </div>
          <div className={styles.rightContent}>
            <span className={styles.price}>{getFormattedPrice(price)}</span>
            <Arrow className={styles.icon} direction="right" color="#cf2e05" />
          </div>
        </>
      </Link>
    </li>
  )
}
export default MealItem