import * as React from 'react'
import { connect } from 'react-redux'
import { handleOpeningHours } from '../../../utils/openingHours'
import type { RootState, Dispatch } from '../../../app/store'
import type { MenuT, MenuMealsT, StatusT } from '../../../models/menus'
import Page from '../../layout/Page'
import Loading from '../../Loading'
import MealItem from '../../menus/MealItem'
import ClosedBanner from '../../ClosedBanner'
import MessageBanner from '../../MessageBanner'
import styles from './Meals.module.css'
import { useLocation, useParams } from 'react-router'

interface MealsT {
  mealStatus: StatusT,
  menus: Array<MenuT>,
  fetchMeals: (payload: { storeId: string, restaurantId: string }) => void,
  menuMeals?: MenuMealsT | null,
  resetCurrentProduct: () => void
}

function Meals ({ mealStatus, menus, fetchMeals, menuMeals, resetCurrentProduct }: MealsT) {
  const [currentMenu, setCurrentMenu] = React.useState<MenuT | null>(null)
  const { name, description, restaurantMessage } = menuMeals || {}
  const { storeId, restaurantId } = useParams()
  const location = useLocation()

  React.useEffect(() => {
    storeId && restaurantId &&
      fetchMeals({
        storeId,
        restaurantId
      })
    resetCurrentProduct()
  }, [fetchMeals, restaurantId, storeId, resetCurrentProduct])

  React.useEffect(() => {
    const menu = menus.find((item: MenuT) => item.id === restaurantId)
    if (menu) {
      setCurrentMenu(menu)
    }
  }, [menus, restaurantId])

  function getNumberOfMeals () {
    return menuMeals?.categories.reduce((sum, current) => {
      return sum + current.products.length
    }, 0)
  }

  let isOpen: boolean = true;
  let openingDetails: { opening?: string, closes?: string } = {}

  if (currentMenu) {
    const openingHours = handleOpeningHours(currentMenu?.openingHours) || {}
    isOpen = openingHours.isOpen
    openingDetails = openingHours.openingDetails
  }

  return (
    <Page withTopNav={true} withCancelButton={true} withCart={true}>
      <div className={styles.content}>
        {mealStatus === "FETCHING" ? (
          <Loading heading="Laddar meny" />
        ) : (
          <>
            <h1 className={styles.heading}>{name && (<>{`${name} (${getNumberOfMeals()})`}</>)}</h1>
            {description && (
              <p className={styles.preamble}>{description}</p>
            )}
            {isOpen && restaurantMessage && (
              <MessageBanner title={restaurantMessage.title} description={restaurantMessage.text} />
            )}
            {currentMenu && !isOpen && (
              <ClosedBanner openingTime={openingDetails?.opening || ""} />
            )}
            {menuMeals?.categories?.map((category, index) => (
              <section key={index} className={styles.category}>
                <h2 className={styles.categoryHeading}>{category.name}</h2>
                {category.description && (
                  <p className={styles.categoryPreamble}>{category.description}</p>
                )}
                {category.products.length > 0 && (
                  <ul className={styles.list}>
                    {category.products.map(product => (
                      <MealItem key={product.id} href={`${location.pathname}/meny/${category.id}/produkt/${product.id}`} meal={product} />
                    ))}
                  </ul>
                )}
              </section>
            ))}
          </>
        )}
      </div>
    </Page>
  )
}


function mapState (state: RootState) {
  return {
    mealStatus: state.menus.mealStatus,
    menus: state.menus.items,
    menuMeals: state.menus.meals,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchMeals: dispatch.menus.fetchMealsInMenu,
    resetCurrentProduct: () => dispatch.menus.setProduct(null)
  };
}


export default connect(mapState, mapDispatch)(Meals)