import * as React from 'react'
import Cart from '../cart'
import Header from './Header'
import TopNav from '../../components/menus/TopNav'
import styles from './Page.module.css'

type PageT = {
  withHeader?: boolean,
  withTopNav?: boolean,
  withCart?: boolean,
  withCancelButton?: boolean, 
  withBackButton?: boolean,
  children: React.ReactNode
}

function Page({ withHeader = true, withCart = false, withTopNav = false, withBackButton = false, withCancelButton = false, children, ...props }: PageT) {
  return (
    <div className={styles.page}>
      {withHeader === true && (
        <Header withCancelButton={withCancelButton} />
      )}
      <div className={withTopNav ? styles.pageContentWithTopNav : styles.pageContent}>
        {withTopNav && (
          <TopNav withBackButton={withBackButton} />
        )}
        <div className={styles.mainContent}>
          <div className={withCart ? styles.contentWithCart : styles.content}>{children}</div>
          {withCart && (
            <div className={styles.cart}>
              <Cart />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Page