type OpeningDetailsT = {
  day: number,
  open: string,
  close: string,
  specialDay: boolean,
  extraInfo?: string,
}

export function handleOpeningHours (openingHours: Array<OpeningDetailsT>) {
  let openingData = {
    isOpen: false,
    openingDetails: {},
  }
  const today = new Date();
  const currentDay = today.getDay()
  const openingHoursToday = openingHours.find(item => item.day === currentDay)

  if (openingHoursToday) {
    const { open, close } = openingHoursToday
    const openParts = open.split(':')
    const closeParts = close.split(':')
    if (openParts.length === 2 && closeParts.length === 2) {
      const openHour = parseInt(openParts[0])
      const openMinute = parseInt(openParts[1])
      const openDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), openHour, openMinute, 0);
      const closeHour = parseInt(closeParts[0])
      const closeMinute = parseInt(closeParts[1])
      const closeDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), closeHour, closeMinute, 0);
      openingData['isOpen'] = openDate < today && closeDate > today
    }
    openingData['openingDetails'] = {
      opening: open,
      closes: close,
    }
  } else {
    let opensNext: Partial<OpeningDetailsT> | undefined = undefined
    let iteration = 0
    while (!opensNext && iteration < 7) {
      const nextDay = currentDay + iteration > 6 ? currentDay + iteration - 7 : currentDay + iteration + 1
      opensNext = openingHours.find(item => item.day === nextDay)
      if (opensNext) {
        openingData['openingDetails'] = { opening: `${getDayInText(nextDay)} ${opensNext.open}` }
      }
      iteration++
    }
  }
  return openingData
}
function getDayInText (day: number) {
  const days = ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag']
  return days[day]
}