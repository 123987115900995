import * as React from 'react'
import Clock from '../icons/Clock'
import styles from './ClosedBanner.module.css'

type ClosedBannerT = {
  openingTime: string,
}

function ClosedBanner ({ openingTime }: ClosedBannerT) {
  return (
    <div className={styles.closedBanner}>
      <Clock color="#9b0600" className={styles.icon} />
      <h3 className={styles.heading}>Denna meny öppnar {openingTime}</h3>
      <p className={styles.openingText}>Välkommen då!</p>
    </div>
  )
}

export default ClosedBanner