import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from './app/routes';
import ScrollToTop from './components/ScrollToTop'
import './custom.css'
import registerServiceWorker from './registerServiceWorker';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>
);

registerServiceWorker();
