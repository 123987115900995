import * as React from 'react'
import loader from '../images/loading.gif'
import styles from './Loading.module.css'

function Loading ({ heading = "Laddar" }: { heading?: string}) {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingContent}>
        <h2 className={styles.heading}>{heading}</h2>
        <img className={styles.icon} src={loader} alt="laddningsikon" />
      </div>
    </div>
  )
}
export default Loading