import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import type { RootState, Dispatch } from '../../app/store';
import type { StoreT } from '../../models/store';
import Close from '../../icons/Close';
import styles from './Header.module.css';


const lightModeStartPage = {
  täby: '0594bfa2-9dc1-423d-53a0-08d6d842082e',
  roslagstull: '8ea8885a-0930-4a4b-0bab-08db0f57590e',
}
interface HeaderT {
  fetchStore: (payload: string) => void;
  store?: StoreT | null;
  withCancelButton?: boolean;
  children?: React.ReactNode;
  resetCart: () => void;
}

function Header ({
  resetCart,
  fetchStore,
  store,
  withCancelButton,
  children,
}: HeaderT) {
  const { storeId } = useParams()
  const { name, logo, alternateLogo } = store || {};
  const navigate = useNavigate();

  const logoUrl = React.useMemo(() => {
    const selectedLogo = alternateLogo || logo || ''
    return Object.values(lightModeStartPage).includes(storeId as string) ?
      selectedLogo.replace('upload/', 'upload/e_bgremoval/') : selectedLogo

  }, [alternateLogo, logo])

  const getStore = React.useCallback(() => {
    storeId && fetchStore(storeId);
  }, [fetchStore, storeId]);

  React.useEffect(() => {
    if (!store || !store.hasOwnProperty('name')) {
      getStore();
    }
  }, [getStore, store]);

  function onCancelOrder () {
    resetCart();
    navigate(`/butik/${storeId}`);
  }

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <a className={styles.link} href={`/butik/${storeId}`}>
          {logoUrl && (
            <img className={styles.storeLogo} src={logoUrl} alt={`${name} logo`} />
          )}
          <p className={styles.storeName}>{name}</p>
          {children}
        </a>
      </div>
      {withCancelButton === true && (
        <button
          type="button"
          onClick={onCancelOrder}
          className={styles.cancelButton}
        >
          <span className={styles.cancelText}>Avbryt order</span>
          <Close color="#cf2e05" className={styles.cancelIcon} />
        </button>
      )}
    </header>
  );
}

function mapState (state: RootState) {
  return {
    store: state.store.current,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchStore: dispatch.store.fetchStore,
    resetCart: () => dispatch({ type: 'RESET_CART' }),
  };
}

export default connect(mapState, mapDispatch)(Header);
