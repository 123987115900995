import * as React from 'react'
import { connect } from 'react-redux'
import type { RootState, Dispatch } from '../../app/store'
import { getFormattedPrice } from '../../utils/formattedPrice'
import type { AddOnT } from '../../models/menus'
import QuantityButton from '../QuantityButton'
import styles from './ModalAddOns.module.css'

type AddOnsT = {
  storeId: string,
  restaurantId: string,
  addOns: Array<AddOnT>
  fetchAddOns: (restaurantId: string) => void
  addItemsToCart: (payload: {
    itemsToAdd: Array<{
      productId: string, categoryId: string, itemType: number, amount: number
    }>,
    storeId: string,
  }) => any,
  onContinue: () => void
}


function ModalAddOns ({ addOns, fetchAddOns, addItemsToCart, onContinue, storeId, restaurantId }: AddOnsT) {
  const [addOnSelections, setAddOnSelections] = React.useState<Array<any>>([])
  const [selectedItems, setSelectedItems] = React.useState<Array<any>>([])
  const [numberOfSelections, setNumberOfSelections] = React.useState<number>(0)

  React.useEffect(() => {
    fetchAddOns(restaurantId)
  }, [fetchAddOns, restaurantId])


  React.useEffect(() => {
    if (addOns.length > 0 && addOnSelections.length === 0) {
      setAddOnSelections(addOns)
    }
  }, [addOns, addOnSelections])


  React.useEffect(() => {
    setSelectedItems(addOnSelections.reduce((prev, current) => {
      const productsWithSelections = current.products.filter((product: any) => product.amount > 0)
      return [
        ...prev,
        ...productsWithSelections
      ]
    }, []))
  }, [addOnSelections])

  React.useEffect(() => {
    let selections = 0

    if (selectedItems && selectedItems.length > 0) {
      selections = selectedItems.reduce((prev: number, current: any) => {
        if (current.hasOwnProperty('amount')) {
          return prev + current.amount
        }
        return prev
      }, 0)
    }
    setNumberOfSelections(selections)

  }, [selectedItems])

  function onItemChange (type: string, groupId: string, id: string) {
    setAddOnSelections(prev => ([...prev.map((selectionAddon: any) => {
      if (selectionAddon.id === groupId) {
        return {
          ...selectionAddon,
          products: selectionAddon.products.map((product: any) => {
            if (product.id === id) {
              return {
                ...product,
                amount: type === 'INCREASE' ? product.amount + 1 : type === 'DECREASE' && product.amount > 0 ? product.amount - 1 : product.amount
              }
            }
            return product
          })
        }
      }
      return { ...selectionAddon }
    })]))
  }

  function getQuantityFor (groupId: string, id: string): number {
    if (addOnSelections && addOnSelections.length > 0) {
      const group = addOnSelections.find((selectionAddon: any) => selectionAddon.id === groupId)
      if (group && group.products.length > 0) {
        const item = group.products.find((product: any) => product.id === id)
        if (item) {
          return item.amount
        }
      }
    }
    return 0
  }

  async function onSubmit () {
    await addItemsToCart({
      storeId,
      itemsToAdd: selectedItems.map((item: any) => {
        return {
          productId: item.id,
          categoryId: item.categoryId,
          itemType: item.itemType,
          amount: item.amount
        }
      })
    })
    onContinue()
  }



  return addOns && addOns.length > 0 ? (
    <div className={styles.addons}>
      <div className={styles.addonsHeader}>
        <h2 className={styles.heading}>Vill du också ha?</h2>
      </div>
      {addOns.map(({ Name, products, ...group }) => (
        <div className={styles.group} key={group.id}>
          <h3 className={styles.groupHeading}>{Name}</h3>
          <ul className={styles.list}>
            {products.map(({ id, imageUrl, groceryDisplayName, price, categoryId }) => (
              <li className={styles.item} key={id}>
                <div className={styles.itemContent}>
                  <div className={styles.info}>
                    <img className={styles.image} src={imageUrl} alt={groceryDisplayName} />
                    <p className={styles.name}>{groceryDisplayName}</p>
                  </div>
                  <div className={styles.summary}>
                    <span className={styles.price}>{getFormattedPrice(price || 0)}</span>
                    <QuantityButton quantity={getQuantityFor(group.id, id)} onDecrease={() => onItemChange('DECREASE', group.id, id)} onIncrease={() => onItemChange('INCREASE', group.id, id)} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className={styles.action}>
        <span className={styles.numberOfSelections}>{`${numberOfSelections} artiklar`}</span>
        <button className={numberOfSelections === 0 ? styles.continueButton : styles.activeContinueButton} onClick={onSubmit} type="button">
          {numberOfSelections === 0 ? 'Hoppa över' : 'Gå vidare'}
        </button>
      </div>
    </div>
  ) : null
}

function mapState (state: RootState) {
  return {
    addOns: state.menus.addOns,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchAddOns: dispatch.menus.fetchAddOns,
    addItemsToCart: dispatch.cart.addItemsToCart,
  };
}

export default connect(mapState, mapDispatch)(ModalAddOns)