import { Models } from '@rematch/core'
import { menus } from './menus'
import { cart } from './cart'
import { store } from './store'
import { order } from './order'
import { customer } from './customer'

export interface RootModel extends Models<RootModel> {
  menus: typeof menus,
  cart: typeof cart,
  store: typeof store,
  order: typeof order,
  customer: typeof customer
}

export const models: RootModel = { menus, cart, store, order, customer }