import React from 'react';
import Payment from '../../Payment';
import styles from './MobilePayment.module.css';
import { useLocation, useParams } from 'react-router';

function MobilePayment () {
  const STORAGE_NAME = 'payexref';
  const { storeId, orderId } = useParams()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search);
  const refParams = urlSearchParams.get('ref');
  let paymentRef = refParams || null;

  if (refParams) {
    localStorage.setItem(STORAGE_NAME, refParams);
  } else {
    const localRef = localStorage.getItem(STORAGE_NAME);
    if (localRef) {
      paymentRef = localRef;
    }
  }

  const decodedRef = paymentRef ? atob(decodeURIComponent(paymentRef)) : null;

  return (
    <div className={styles.payment}>
      <Payment paymentHref={decodedRef} orderId={orderId} storeId={storeId} />
    </div>
  );
}

export default MobilePayment;
