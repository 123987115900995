import React from 'react';
import { connect } from 'react-redux';
import type { RootState, Dispatch } from '../app/store';
import styles from './Payment.module.css';
import { useNavigate } from 'react-router';

interface PayexWindow extends Window {
  payex: any;
}
declare var window: PayexWindow;

function Payment ({
  paymentHref,
  orderId,
  storeId,
  verifyPayment,
  paymentStatus,
}: any) {
  const navigate = useNavigate()
  const paymentEl = React.useRef<any>(null);
  const [paymentSuccess, setPaymentSuccess] = React.useState<
    'IDLE' | 'ERROR' | 'TRUE'
  >('IDLE');

  const appendIframe = React.useCallback(() => {
    if (paymentEl.current?.children?.length > 0) {
      paymentEl?.current.removeChild(paymentEl?.current.children[0]);
    }
    const script = document.createElement('script');
    script.src = paymentHref;
    script.async = true;
    script.onload = handlePayments;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [paymentHref]);

  React.useEffect(() => {
    if (paymentHref && paymentEl) {
      appendIframe();
    }
  }, [paymentHref, paymentEl, appendIframe]);

  React.useEffect(() => {
    if (paymentSuccess === 'TRUE') {
      verifyPayment({ orderId, storeId });
    } else if (paymentSuccess === 'ERROR') {
      verifyPayment({ orderId, storeId });
    }
  }, [paymentSuccess, orderId, storeId, verifyPayment]);

  React.useEffect(() => {
    if (paymentStatus && (paymentStatus.toLowerCase() === 'completed' || paymentStatus === 200)) {
      localStorage?.removeItem('payexref');
      navigate(`/butik/${storeId}/${orderId}/tack`);
    }
  }, [paymentStatus, storeId, orderId, navigate]);

  function handlePayments () {
    if (window.payex) {
      const { paymentMenu } = window.payex?.hostedView || null;

      if (paymentMenu && typeof paymentMenu === 'function') {
        paymentMenu({
          container: 'payment-frame',
          onPaymentCompleted: () => {
            setPaymentSuccess('TRUE');
          },
          onPaymentFailed: () => {
            setPaymentSuccess('ERROR');
          },
          onError: () => {
            setPaymentSuccess('ERROR');
          },
          onPaymentMenuInstrumentSelected: () => {
            console.log('onPaymentMenuInstrumentSelected');
          },
          style: {
            button: {
              borderRadius: '5rem',
            },
          },
        }).open();
      }
    }
  }

  return (
    <div className={styles.payment}>
      <h1 className={styles.heading}>Betalning</h1>
      <div className={styles.cardFrame}>
        <div ref={paymentEl} id="payment-frame"></div>
      </div>
    </div>
  );
}

function mapState (state: RootState) {
  return {
    paymentStatus: state.order.paymentStatus,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    verifyPayment: dispatch.order.verifyPayment,
  };
}

export default connect(mapState, mapDispatch)(Payment);
