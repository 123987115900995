
import axios from 'axios'

type FetchOptionsT = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  body?: any,
}

export default async function fetchFrom (endpoint: string, options?: FetchOptionsT): Promise<any> {

  let baseUrl = 'https://api-staging.icapronto.se';
  let apiKey = "17355fd0-a872-4dec-aa3d-2313845b3c91"

  if (process.env.REACT_APP_ENV === 'stage') {
    baseUrl = 'https://api-staging.icapronto.se';
    apiKey = "17355fd0-a872-4dec-aa3d-2313845b3c91"
  } else if (process.env.REACT_APP_ENV === 'production') {
    baseUrl = 'https://api.icapronto.se';
    apiKey = "0bfdd15c-7251-473d-9cd8-0729762e18fa"
  }

  const requestUrl = baseUrl + endpoint
  const headers = {
    "Cache-Control": "no-cache",
    'x-client-name': 'icakiosk',
    'x-api-key': apiKey,
  }
  if (options?.method === 'POST') {
    try {
      const { status, data } = await axios.post(requestUrl, options.body, {
        headers,
      })
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      const e = error ? error : 'Something went wrong while posting data'
      return new Error(e)
    }
  } else if (options?.method === 'PUT') {
    try {
      const { status, data } = await axios.put(requestUrl, options.body, {
        headers,
      })

      if (status === 200) {
        return data
      }
    } catch (error: any) {
      const e = error ? error : 'Something went wrong while updating data'
      return new Error(e)
    }
  } else if (options?.method === 'PATCH') {
    try {
      const { status, data } = await axios.patch(requestUrl, options.body, {
        headers,
      })
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      const e = error ? error : 'Something went wrong while updating data'
      return new Error(e)
    }
  } else if (options?.method === 'DELETE') {
    try {
      const { status, data } = await axios.delete(requestUrl, {
        headers,
      })
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      const e = error ? error : 'Something went wrong while updating data'
      return new Error(e)
    }
  } else {
    try {
      const { status, data } = await axios.get(requestUrl, {
        headers,
      })
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      const e = error ? error : 'Something went wrong while fetching data'
      return new Error(e)
    }
  }

}
export { fetchFrom } 