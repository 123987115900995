import * as React from 'react'
import styles from './Checkbox.module.css'

type CheckboxT = {
  id: string,
  name?: string,
  children?: React.ReactNode
  checked: boolean,
  onChange: () => void,
}

function Checkbox ({ id, name = 'checkbox', checked, onChange, children }: CheckboxT) {
  return (
    <div style={{ paddingBottom: '1rem' }}>
      <label className={checked ? styles.activeLabel : styles.label} htmlFor={id}>
        <span style={{
          backgroundImage: checked ? "url(/check.svg)" : 'none',
        }}
          className={styles.check} />
        {children}
      </label>
      <input className={styles.input} id={id} name={name} type="checkbox" onChange={onChange} checked={checked} />
    </div>
  )
}

export default Checkbox