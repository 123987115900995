import React from 'react'
import RadioButton from './RadioButton'
import Checkbox from './Checkbox'
import { getFormattedPrice } from '../utils/formattedPrice'
import styles from './Customizations.module.css'

type CustomizationsT = {
  itemsToChange: Array<any>,
  itemsToAdd: Array<any>,
  itemsToRemove: Array<any>,
  updateItemsToChange: any,
  updateItemsToAdd: any,
  updateItemsToRemove: any,
  customizations: {
    customizable: Array<{
      id: string,
      name: string,
      customizationMenuItems: Array<{
        id: string,
        name: string,
        price: number,
        preSelected: boolean,
      }>
    }>,
    addable: Array<{
      id: string,
      name: string,
      price: number,
    }>,
    removable: Array<{
      id: string,
      name: string,
    }>,
  }
}

function Customizations ({ itemsToChange, itemsToAdd, itemsToRemove, updateItemsToChange, updateItemsToAdd, updateItemsToRemove, customizations }: CustomizationsT) {

  const { customizable, addable, removable } = customizations || {};

  function onChange (group: any, item: any) {
    const selectedGroup = itemsToChange.find(g => g.id === group.id)
    if (!selectedGroup) {
      const updatedItems = group.customizationMenuItems.map((groupItem: any) => {
        if (groupItem.id === item.id) {
          return {
            ...groupItem,
            selected: true
          }
        } else {
          return {
            ...groupItem,
            selected: false
          }
        }
      })
      updateItemsToChange((prev: any) => [...prev, {
        ...group,
        items: updatedItems
      }])
    } else {
      const updatedSelectedGroup = selectedGroup.items.map((groupItem: any) => {
        if (groupItem.id === item.id) {
          return {
            ...groupItem,
            selected: true
          }
        }
        return {
          ...groupItem,
          selected: false
        }
      })
      const otherGroups = itemsToChange.filter(i => i.id !== group.id)
      updateItemsToChange([...otherGroups, { ...selectedGroup, items: updatedSelectedGroup }])
    }
  }

  function onAdd (item: any) {
    const itemExists = itemsToAdd.find(i => i.id === item.id)
    if (!itemExists) {
      updateItemsToAdd((prev: Array<any>) => [...prev, item])
    } else {
      updateItemsToAdd((prev: Array<any>) => prev.filter(i => i.id !== item.id))
    }
  }
  function isSelectedAdd (item: any) {
    const itemExists = itemsToAdd.find(i => i.id === item.id)
    if (itemExists) {
      return true
    }
    return false
  }
  function onRemove (item: any) {
    const itemExists = itemsToRemove.find(i => i.id === item.id)
    if (!itemExists) {
      updateItemsToRemove((prev: Array<any>) => [...prev, item])
    } else {
      updateItemsToRemove((prev: Array<any>) => prev.filter(i => i.id !== item.id))
    }
  }
  function isSelectedRemove (item: any) {
    const itemExists = itemsToRemove.find(i => i.id === item.id)
    if (itemExists) {
      return false
    }
    return true
  }

  return (
    <div className={styles.customizations}>
      {customizable && customizable.length > 0 && (
        <div className={styles.col}>
          <h3 className={styles.colHeading}>Byt ut</h3>

          <ul>
            {customizable.map((group: any, index: number) => (
              <li className={styles.group} key={index}>
                <h4 className={styles.groupTitle}>{group.name}</h4>
                {group.customizationMenuItems.map((item: any, i: number) => (
                  <div className={styles.item} key={i}>
                    <label key={i}>
                      <RadioButton checked={item.preSelected} id={`group${index}${i}`} name={`group-${index}`} onChange={() => onChange(group, item)}>
                        {item.name} {item.price > 0 ? (
                          <span className={styles.price}>+{getFormattedPrice(item.price)}</span>
                        ) : null}
                      </RadioButton>
                    </label>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </div>
      )}
      {addable && addable.length > 0 && (
        <div className={styles.col}>
          <h3 className={styles.colHeading}>Lägg till</h3>
          <ul>
            {addable.map((item: any, index: number) => (
              <li className={styles.item} key={`addableItem-${index}`}>
                <Checkbox id={`addable-${index}`} checked={isSelectedAdd(item)} onChange={() => onAdd(item)}>
                  {item.name} {item.price > 0 ? (
                    <span className={styles.price}>+{getFormattedPrice(item.price)}</span>
                  ) : null}
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
      )}
      {removable && removable.length > 0 && (
        <div className={styles.col}>
          <h3 className={styles.colHeading}>Ta bort</h3>
          <ul>
            {removable.map((item: any, index: number) => (
              <li className={styles.item} key={index}>
                <Checkbox id={`removable-${index}`} checked={!isSelectedRemove(item)} onChange={() => onRemove(item)}>
                  {item.name} {item.price > 0 ? (
                    <span className={styles.price}>+{getFormattedPrice(item.price)}</span>
                  ) : null}
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Customizations