import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import { models, RootModel } from '../models'

export const store = init({
  models,
  redux: {
    rootReducers: {
      RESET_CART: (state, action) => {
        if(action.type === 'RESET_CART') {
          const { store, menus } = state
          state = { store, menus }
        }
        return state
      },
    },
  }
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
