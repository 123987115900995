import * as React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import type { RootState, Dispatch } from '../../app/store';
import type { MenuT } from '../../models/menus';
import Carousel from '../Carousel';
import Arrow from '../../icons/Arrow';
import styles from './TopNav.module.css';

interface TopMenuT {
  withBackButton: boolean;
  fetchMenus: (storeId: string) => void;
  menuItems: Array<MenuT>;
}

function TopNav ({ fetchMenus, withBackButton, menuItems }: TopMenuT) {
  const { storeId, restaurantId } = useParams()
  const location = useLocation();
  React.useEffect(() => {
    storeId && fetchMenus(storeId);
  }, [fetchMenus, storeId]);

  function isActiveMenu (navPath: string) {
    if (restaurantId) {
      const pathParts = navPath.split('/').filter(Boolean);
      if (pathParts && pathParts.length >= 3) {
        return pathParts[3] === restaurantId;
      }
    } else {
      if (location.pathname === navPath) {
        return true;
      }
    }
    return false;
  }

  return withBackButton === true ? (
    <div className={styles.nav}>
      <Link
        className={styles.backButton}
        to={`/butik/${storeId}/restaurant/${restaurantId}`}
      >
        <Arrow direction="left" color="#cf2e05" className={styles.backIcon} />
        <span>Tillbaka till meny</span>
      </Link>
    </div>
  ) : (
    <div className={styles.nav}>
      <div>
        <Link
          className={
            isActiveMenu(`/butik/${storeId}/restaurant`)
              ? styles.activeMenuButton
              : styles.menuButton
          }
          to={`/butik/${storeId}/restaurant`}
        >
          Alla menyer
        </Link>
      </div>
      {storeId && <Carousel
        isActiveMenu={isActiveMenu}
        items={menuItems}
        storeId={storeId}
      />}
    </div>
  );
}

function mapState (state: RootState) {
  return {
    menuItems: state.menus.items,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchMenus: dispatch.menus.fetchMenus,
  };
}

export default connect(mapState, mapDispatch)(TopNav);
