import * as React from 'react';
import { connect } from 'react-redux';
import type { RootState, Dispatch } from '../../../app/store';
import type { StatusT, BonusStatusT } from '../../../models/customer';
import Page from '../../layout/Page';
import Keyboard from '../../Keyboard';
import Arrow from '../../../icons/Arrow';
import Checkmark from '../../../icons/Checkmark';
import styles from './Stammis.module.css';
import { useNavigate, useParams } from 'react-router';

interface StammisT {
  addBonus: (payload: {
    id: string;
    storeId: string;
    orderId: string | null;
  }) => void;
  swishToken: string | null;
  orderId: string | null;
  bonusStatus: BonusStatusT;
  status: StatusT;
}

function Stammis ({
  addBonus,
  status,
  bonusStatus,
  orderId,
  swishToken,
}: StammisT) {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [isRegularCustomer, setIsRegularCustomer] = React.useState('IDLE');
  const [personalNumber, setPersonalNumber] = React.useState('');
  const [validPersonalNumber, setValidPersonalNumber] = React.useState('IDLE');
  const [readyToPay, setReadyToPay] = React.useState(false);

  const isValidPersonalNumber = React.useCallback(() => {
    try {
      if (personalNumber.length !== 10) {
        setValidPersonalNumber('FALSE');
        return false;
      }

      const month = personalNumber.substring(2, 4);
      const day = personalNumber.substring(4, 6);

      if (parseInt(month) > 12 || parseInt(day) > 31) {
        setValidPersonalNumber('FALSE');
        return false;
      }

      const personalSum = [...new Array(9)].reduce((prev, _, index) => {
        let number = 0;
        if (index % 2) {
          number = parseInt(personalNumber[index]) * 1;
        } else {
          number = parseInt(personalNumber[index]) * 2;
        }
        let num = number
          .toString()
          .split('')
          .reduce((prev, current) => {
            return prev + parseInt(current);
          }, 0);

        return prev + num;
      }, 0);

      const nearestTen = Math.ceil(personalSum / 10) * 10;
      const controlNumber = nearestTen - personalSum;

      if (parseInt(personalNumber.charAt(9)) !== controlNumber) {
        setValidPersonalNumber('FALSE');
        return false;
      }

      setValidPersonalNumber('TRUE');
      return true;
    } catch (error) {
      console.log(error);
      setValidPersonalNumber('FALSE');
      return false;
    }
  }, [personalNumber]);

  React.useEffect(() => {
    if (readyToPay === true && storeId) {
      navigate(`/butik/${storeId}/qr`);
    }
  }, [readyToPay, storeId, navigate]);

  React.useEffect(() => {
    setPersonalNumber('');
  }, [isRegularCustomer]);

  React.useEffect(() => {
    if (personalNumber.length === 10) {
      isValidPersonalNumber();
    } else {
      setValidPersonalNumber('IDLE');
    }
  }, [personalNumber, isValidPersonalNumber]);

  React.useEffect(() => {
    if (bonusStatus === 'REGISTERED') {
      setTimeout(() => {
        setReadyToPay(true);
      }, 2000);
    } else if (bonusStatus === 'FAILED') {
      setValidPersonalNumber('FALSE');
    }
  }, [bonusStatus]);

  function handleKeyboardClick (value: number) {
    if (value === -1 && personalNumber.length > 0) {
      setPersonalNumber((prev) => prev.slice(0, -1));
    } else if (value !== -1 && personalNumber.length < 10) {
      setPersonalNumber((prev) => `${prev}${value}`);
    }
  }

  async function registerPoints () {
    if (isValidPersonalNumber() && storeId) {
      await addBonus({ storeId, id: personalNumber, orderId });
    }
  }

  function handleRegularCustomerClick (isRegular: 'TRUE' | 'FALSE') {
    setIsRegularCustomer(isRegular);
    if (isRegular === 'FALSE') {
      setReadyToPay(true);
    }
  }

  return (
    <Page
      withTopNav={false}
      withBackButton={false}
      withCancelButton={true}
      withCart={false}
    >
      {bonusStatus === 'REGISTERED' ? (
        <section className={styles.stammis}>
          <div className={styles.successContainer}>
            <div className={styles.successContent}>
              <h3 className={styles.successHeading}>
                Dina stammispoäng är registrerade!
              </h3>
              <Checkmark className={styles.checkmark} />
            </div>
          </div>
        </section>
      ) : (
        <>
          {isRegularCustomer === 'TRUE' ? (
            <>
              <button
                className={styles.backButton}
                type="button"
                onClick={() => setIsRegularCustomer('IDLE')}
              >
                <Arrow
                  direction="left"
                  color="#cf2e05"
                  className={styles.backIcon}
                />
                Tillbaka
              </button>
              <section className={styles.stammis}>
                <div className={styles.content}>
                  <h1 className={styles.heading}>Skriv ditt personnummer</h1>
                  <p className={styles.preamble}>10 siffror</p>
                  <div className={styles.personalNumber}>
                    {[...new Array(10)].map((_, index) => (
                      <>
                        <span
                          key={index}
                          className={
                            validPersonalNumber === 'FALSE'
                              ? styles.errorValueBox
                              : validPersonalNumber === 'TRUE'
                                ? styles.validValueBox
                                : styles.valueBox
                          }
                        >
                          {personalNumber[index]}
                        </span>
                        {index === 5 && (
                          <span className={styles.separator}>-</span>
                        )}
                      </>
                    ))}
                  </div>
                  {validPersonalNumber === 'FALSE' && (
                    <p className={styles.errorMessage}>
                      Kontrollera det angivna numret
                    </p>
                  )}
                  <Keyboard onClick={handleKeyboardClick} />
                  <button
                    disabled={status === 'FETCHING'}
                    className={styles.continueButton}
                    type="button"
                    onClick={registerPoints}
                  >
                    {status === 'FETCHING' ? <>Laddar…</> : <>Fortsätt</>}
                  </button>
                </div>
              </section>
            </>
          ) : (
            <section className={styles.stammis}>
              <div className={styles.content}>
                <h1 className={styles.heading}>Är du ICA Stammis?</h1>
                <div className={styles.preamble}>
                  Som ICA Stammis kan du samla poäng på alla dina köp.{' '}
                  <p>Är du inte stammis kan du bli det på ica.se</p>
                </div>
                <div className={styles.buttons}>
                  <button
                    className={styles.button}
                    type="button"
                    onClick={() => handleRegularCustomerClick('TRUE')}
                  >
                    Ja, jag är stammis
                  </button>
                  <button
                    className={styles.button}
                    type="button"
                    onClick={() => handleRegularCustomerClick('FALSE')}
                  >
                    Nej, gå vidare
                  </button>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </Page>
  );
}

function mapState (state: RootState) {
  return {
    swishToken: state.order.swishToken,
    orderId: state.order.currentOrderId,
    bonusStatus: state.customer.bonusStatus,
    status: state.customer.status,
  };
}

function mapDispatch (dispatch: Dispatch) {
  return {
    addBonus: dispatch.customer.addBonus,
  };
}

export default connect(mapState, mapDispatch)(Stammis);
