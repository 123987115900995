import React from 'react'
import { connect } from 'react-redux'
import type { RootState, Dispatch } from '../../../app/store'
import { Link }  from 'react-router-dom'
import styles from './Stores.module.css'

type StoresT = {
  fetchAllStores: (payload?: any) => void
  stores: Array<{
    name: string,
    id: string,
  }>
}

function Stores({ fetchAllStores, stores }: StoresT) {

  React.useEffect(() => {
    fetchAllStores()
  }, [fetchAllStores])

  return (
    <div className={styles.stores}>
      <h1 className={styles.heading}>Välj din butik</h1>
      <ul className={styles.storeList}>
        {stores.map(({ id, name}) => (
          <li className={styles.storeItem} key={id}>
            <Link className={styles.link} to={`/butik/${id}`}>
              <h2 className={styles.cardHeading}>{name}</h2>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

function mapState(state: RootState) {
  return {
    stores: state.store.stores
  }
}

function mapDispatch(dispatch: Dispatch) {
  return {
    fetchAllStores: dispatch.store.fetchAllStores,
  };
}

export default connect(mapState, mapDispatch)(Stores)