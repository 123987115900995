import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Start from '../components/pages/Start';
import Menus from '../components/pages/Menus';
import Meals from '../components/pages/Meals';
import Product from '../components/pages/Product';
import Stores from '../components/pages/Stores';
import Confirmation from '../components/pages/Confirmation';
import Stammis from '../components/pages/Stammis';
import QR from '../components/pages/QR';
import MobilePayment from '../components/pages/MobilePayment';
import MobileThanks from '../components/pages/MobilePayment/thanks';
import Setup from '../components/pages/Setup';

function RoutesData () {
  return (
    <Routes>
      <Route
        path="/butik/:storeId/betala/:orderId"
        element={<MobilePayment />}
      />
      <Route
        path="/butik/:storeId/:orderId/tack"
        element={<MobileThanks />}
      />
      <Route path="/butik/:storeId" element={<Start />} />
      <Route
        path="/butik/:storeId/restaurant/:restaurantId/meny/:menuId/produkt/:productId"
        element={<Product />}
      />
      <Route

        path="/butik/:storeId/restaurant/:restaurantId"
        element={<Meals />}
      />
      <Route path="/butik/:storeId/restaurant" element={<Menus />} />
      <Route path="/butik/:storeId/qr" element={<QR />} />
      <Route path="/butik/:storeId/stammis" element={<Stammis />} />
      <Route path="/butik/:storeId/tack" element={<Confirmation />} />
      <Route path="/start/:bms" element={<Setup />} />
      <Route path="/" element={<Stores />} />
    </Routes>
  );
}

export default RoutesData;
