import { createModel } from "@rematch/core";
import { fetchFrom } from '../utils/fetchData'

interface StateT {
  menuType: MenuT,
  items: Array<ItemT>,
  cartId: string | null,
  totalPrice: number,
}

export type MenuT = 'IDLE' | 'EATIN' | 'TOGO'
export type ItemT = {
  id: string,
  name: string,
  price: number,
  totalPrice: number,
  quantity: number,
  extraIngredients: Array<{
    id: string,
    name: string,
    price: number,
  }>
  customizationMenuItems: Array<any>,
}

export const cart = createModel()({
  state: {
    cartId: null,
    menuType: 'IDLE',
    items: [],
    totalPrice: 0,
  } as StateT,
  reducers: {
    setCartId (state, payload: string) {
      return { ...state, cartId: payload };
    },
    setMenuType (state, payload: MenuT) {
      return { ...state, menuType: payload };
    },
    setCartItems (state, payload: Array<ItemT>) {
      return { ...state, items: payload };
    },
    setTotalPrice (state, payload: number) {
      return { ...state, totalPrice: payload };
    },
  },
  effects: (dispatch) => ({
    selectMenuType (payload, state) {
      dispatch.cart.setMenuType(payload)
    },
    async fetchCart (payload, state) {
      const { cartId } = state.cart
      if (cartId) {
        try {
          const { items } = await fetchFrom(`/cart/${cartId}`)

          if (items) {
            const cartItems = items.filter(({ itemType }: { itemType: number }) => itemType !== 600 && itemType !== 700).map(({ id, displayName, unitPrice, customizationMenuItems, quantity, totalPrice }: {
              id: string,
              displayName: string,
              unitPrice: number,
              quantity: number,
              customizationMenuItems: Array<any>,
              totalPrice: number
            }) => ({
              id,
              name: displayName,
              price: unitPrice / 100,
              totalPrice: totalPrice / 100,
              quantity,
              customizationMenuItems: customizationMenuItems.map(cmi => ({
                ...cmi,
                price: cmi.price / 100
              })),
            }))
            const total = cartItems.reduce((prev: number, current: ItemT) => {
              return prev + current.totalPrice
            }, 0)
            dispatch.cart.setCartItems(cartItems)
            dispatch.cart.setTotalPrice(total)
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    async addItemToCart (payload, state) {
      const { productId, storeId, itemType = 100, categoryId, customizationItems, amount = 1 } = payload
      const { cartId } = state.cart

      let currentCartId: any = cartId
      if (!cartId) {
        currentCartId = await dispatch.cart.createCart(storeId)
      }

      try {
        const { items } = await fetchFrom(`/v2/cart/${currentCartId}/add`, {
          method: 'POST',
          body: [{
            id: productId,
            itemType,
            categoryId,
            customizationItems,
            amount,
          }]
        })
        if (items) {
          const cartItems = items.filter(({ itemType }: { itemType: number }) => itemType !== 600 && itemType !== 700).map(({ id, displayName, unitPrice, customizationMenuItems, quantity, totalPrice }: {
            id: string,
            displayName: string,
            unitPrice: number,
            customizationMenuItems: Array<any>,
            quantity: number
            totalPrice: number
          }) => ({
            id,
            name: displayName,
            quantity,
            price: unitPrice / 100,
            totalPrice: totalPrice / 100,
            customizationMenuItems: customizationMenuItems.map(cmi => ({
              ...cmi,
              price: cmi.price / 100
            })),
          }))
          const total = cartItems.reduce((prev: number, current: ItemT) => {
            return prev + current.totalPrice
          }, 0)
          dispatch.cart.setCartItems(cartItems)
          dispatch.cart.setTotalPrice(total)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async addItemsToCart ({ itemsToAdd, storeId }, state) {

      if (!itemsToAdd || itemsToAdd.length < 1) {
        return;
      }

      const { cartId } = state.cart
      let currentCartId: any = cartId
      if (!cartId) {
        currentCartId = await dispatch.cart.createCart(storeId)
      }
      try {
        const { items } = await fetchFrom(`/v2/cart/${currentCartId}/add`, {
          method: 'POST',
          body: itemsToAdd.map((item: any) => {
            return {
              id: item.productId,
              itemType: item.itemType,
              categoryId: item.categoryId,
              customizationItems: item.customizationItems,
              amount: item.amount,
            }
          })
        })
        if (items && items.length > 0) {
          const cartItems = items.filter(({ itemType }: { itemType: number }) => itemType !== 600 && itemType !== 700).map(({ id, displayName, unitPrice, customizationMenuItems, quantity, totalPrice }: {
            id: string,
            displayName: string,
            unitPrice: number,
            quantity: number,
            customizationMenuItems: Array<any>,
            totalPrice: number
          }) => ({
            id,
            name: displayName,
            price: unitPrice / 100,
            quantity,
            totalPrice: totalPrice / 100,
            customizationMenuItems: customizationMenuItems.map(cmi => ({
              ...cmi,
              price: cmi.price / 100

            })),
          }))
          const total = cartItems.reduce((prev: number, current: ItemT) => {
            return prev + current.totalPrice
          }, 0)
          dispatch.cart.setCartItems(cartItems)
          dispatch.cart.setTotalPrice(total)

          return true
        }
        return false
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async removeItemFromCart (cartItemId, state) {

      const { cartId }: { cartId: string | null } = state.cart || ''

      try {
        const { items } = await fetchFrom(`/v2/cart/${cartId}/${cartItemId}`, {
          method: 'DELETE'
        })
        if (items) {
          const cartItems = items.filter(({ itemType }: { itemType: number }) => itemType !== 600 && itemType !== 700).map(({ id, displayName, unitPrice, customizationMenuItems, quantity, totalPrice }: {
            id: string,
            displayName: string,
            unitPrice: number,
            customizationMenuItems: Array<any>
            totalPrice: number,
            quantity: number
          }) => ({
            id,
            name: displayName,
            price: unitPrice / 100,
            totalPrice: totalPrice / 100,
            quantity,
            customizationMenuItems: customizationMenuItems.map(cmi => ({
              ...cmi,
              price: cmi.price / 100
            })),
          }))
          const total = cartItems.reduce((prev: number, current: ItemT) => {
            return prev + current.totalPrice
          }, 0)
          dispatch.cart.setCartItems(cartItems)
          dispatch.cart.setTotalPrice(total)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async createCart (storeId, state) {
      try {
        const { id } = await fetchFrom('/v2/cart', {
          method: 'POST',
          body: {
            storeId
          }
        })

        if (id) {
          const { menuType } = state.cart
          const itemType = menuType === 'TOGO' ? 700 : 600;
          await fetchFrom(`/v2/cart/${id}/add`, {
            method: 'POST',
            body: [{
              id: 'deliveryId',
              itemType,
              amount: 1,
            }]
          })
          dispatch.cart.setCartId(id)
          return id
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateItemQuantity ({ cartItemId, storeId, quantity }, state) {
      const { cartId } = state.cart
      let currentCartId: any = cartId
      if (!cartId) {
        currentCartId = await dispatch.cart.createCart(storeId)
      }
      try {
        const { items } = await fetchFrom(`/v2/cart/${currentCartId}/${cartItemId}`, {
          method: 'PATCH',
          body: {
            quantity
          }
        })
        const cartItems = items.filter(({ itemType }: { itemType: number }) => itemType !== 600 && itemType !== 700).map(({ id, displayName, unitPrice, customizationMenuItems, quantity, totalPrice }: {
          id: string,
          displayName: string,
          unitPrice: number,
          customizationMenuItems: Array<any>
          quantity: number
          totalPrice: number
        }) => ({
          id,
          name: displayName,
          price: unitPrice / 100,
          totalPrice: totalPrice / 100,
          quantity,
          customizationMenuItems: customizationMenuItems.map(cmi => ({
            ...cmi,
            price: cmi.price / 100
          })),
        }))
        const total = cartItems.reduce((prev: number, current: ItemT) => {
          return prev + current.totalPrice
        }, 0)
        dispatch.cart.setCartItems(cartItems)
        dispatch.cart.setTotalPrice(total)
      } catch (error) {
        console.log(error)
      }
    },
  }),
});
