import React from 'react'
import Info from '../icons/Info'
import styles from './MessageBanner.module.css'

type MessageT = {
  title?: string,
  description?: string,
  maxWidth?: string,
}

function MessageBanner({ title = '', description = '', maxWidth = 'none'} : MessageT) {
  return (
    <div style={{ maxWidth }} className={styles.banner}>
      <Info color="#652082" className={styles.icon} />
      <h3 className={styles.heading}>{title}</h3>
      <p className={styles.text}>{description}</p>
    </div>
  )
}

export default MessageBanner