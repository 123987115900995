import { Customization, OrderItem, Receipt } from "../types/Receipt"

export const groupedItems = (receipt: Receipt) => {
    if (receipt?.order?.orderItems && receipt?.order?.orderItems?.length > 0) {
        return {
            ...receipt,
            order: {
                ...receipt.order,
                orderItems: receipt.order.orderItems.reduce((prev, current) => {
                    const name = current.displayName
                    const existing = prev.find(
                        (prevItem) =>
                            prevItem.displayName === name &&
                            prevItem.customizationMenuItems.length === current.customizationMenuItems.length &&
                            withSamecustomization(prevItem.customizationMenuItems, current.customizationMenuItems)
                    )
                    if (existing) {
                        const prevWithoutDouble = prev.filter((prevItem) => prevItem.id !== existing.id)
                        return [
                            ...prevWithoutDouble,
                            {
                                ...existing,
                                quantity: existing.quantity += 1,
                                vatAmount: existing.vatAmount += current.vatAmount,
                                totalPrice: existing.totalPrice += current.totalPrice,
                            },
                        ]
                    } else {
                        return [...prev, { ...current, quantity: 1 }]
                    }
                }, [] as OrderItem[]),
            },
        }
    }
    return receipt
}

function withSamecustomization (array1: Customization[], array2: Customization[]) {
  return array1.every((e1) => array2.some((e2) => e1.id === e2.id))
}
