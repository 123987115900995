import React from 'react';
import { connect } from 'react-redux';
import { RootState, Dispatch } from '../../app/store';
import { useNavigate, useParams } from 'react-router';

function Setup ({ fetchStoreFrom, storeId }: any) {
  const { bms } = useParams()
  const navigate = useNavigate();


  React.useEffect(() => {
    if (bms) {
      fetchStoreFrom(bms);
    }
  }, [bms, fetchStoreFrom]);

  return storeId ? navigate(`/butik/${storeId}`) : null;
}

function mapState (state: RootState) {
  return {
    storeId: state.store.current?.storeId,
  };
}

function mapDispatch (dispatch: Dispatch) {
  return {
    fetchStoreFrom: dispatch.store.fetchStoreFromBms,
  };
}

export default connect(mapState, mapDispatch)(Setup);
