import React from 'react'

type ClockT = {
  color?: string,
  className?: any,
}


function Clock({ color = "#3a3a3a", className }: ClockT) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M7 16c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm-2 0c0 6.065 4.935 11 11 11s11-4.935 11-11S22.065 5 16 5 5 9.935 5 16zm12-.414V9.25a.25.25 0 0 0-.25-.25h-1.5a.25.25 0 0 0-.25.25v7.164l5.116 5.116a.25.25 0 0 0 .354 0l1.06-1.06a.25.25 0 0 0 0-.354L17 15.586z"/></g></svg>
  )
}

export default Clock