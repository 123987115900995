import React from 'react'

type PlusT = {
  color?: string,
  className?: any,
}


function Plus({ color = "#3a3a3a", className }: PlusT) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M17.024 14.994V8.258A.26.26 0 0 0 16.766 8h-1.477a.257.257 0 0 0-.262.262v6.732H9a.249.249 0 0 0-.223.138l-.75 1.5a.25.25 0 0 0 .223.362h6.777v6.748c0 .142.116.258.258.258l1.48-.004a.25.25 0 0 0 .259-.258v-6.744h6.744a.258.258 0 0 0 .259-.259v-1.483a.258.258 0 0 0-.26-.258h-6.743z"/></g></svg>
  )
}

export default Plus