import React from 'react';
import ReactDOM from 'react-dom';

type ModalT = {
  active: boolean,
  onCancel?: () => void,
  onConfirm?: () => void,
  alignItems?: string,
  maxWidth?: string,
  children: React.ReactNode
}

function Modal ({
  active,
  onCancel,
  onConfirm,
  alignItems = 'center',
  maxWidth = 'none',
  children,
}: ModalT) {
  const [container, setContainer] = React.useState<HTMLDivElement>(null!);
  const [content, setContent] = React.useState<HTMLDivElement>(null!);
  const [cancelButton, setCancelButton] = React.useState<HTMLButtonElement>(null!);
  const [modal, setModal] = React.useState<HTMLDivElement>(null!);

  React.useEffect(() => {
    const div = document.createElement('div');
    setModal(div);
  }, []);

  React.useEffect(() => {
    if (typeof document === 'object' && modal) {
      document.body.appendChild(modal);
    }
    return function cleanup () {
      if (typeof document === 'object' && modal) {
        document.body.removeChild(modal);
      }
    };
  }, [modal]);

  React.useEffect(() => {
    if (cancelButton !== null) {
      cancelButton.focus();
    }
  }, [cancelButton]);

  const containerRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);
  const contentRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContent(node);
    }
  }, []);

  const cancelRef = React.useCallback((node: HTMLButtonElement) => {
    if (node !== null) {
      setCancelButton(node);
    }
  }, []);

  function onClick (e: any) {
    e.stopPropagation();
    if (
      (e.target !== cancelButton &&
        !cancelButton.contains(e.target) &&
        content.contains(e.target)) ||
      e.target === content
    ) {
      return;
    }
    e.preventDefault();
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
  }
  function onBlur (e: any) {
    if (container === null) {
      return;
    }
    if (onCancel && typeof onCancel === 'function' && !container.contains(e.relatedTarget)) {
      cancelButton.focus();
    }
  }
  function onKeyDown (e: any) {
    if (e.key === 'Escape' && onCancel && typeof onCancel === 'function') {
      onCancel();
    } else if (e.target === cancelButton && e.key === 'Enter') {
      e.preventDefault();
      if (onConfirm && typeof onConfirm === 'function') {
        onConfirm();
      }
    }
  }

  return modal
    ? ReactDOM.createPortal(
      !active ? null : (
        <div
          style={{
            alignItems: alignItems,
            background: 'rgba(0,0,0,0.25)',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            left: '0',
            padding: '1.5rem',
            position: 'fixed',
            top: '0',
            width: '100%',
            zIndex: 110,
          }}
          onClick={onCancel && typeof onCancel === 'function' ? onClick : () => null}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={containerRef}
        >
          <div
            style={{
              background: '#FFF',
              boxShadow:
                '0 24px 24px 0 rgba(0, 0, 0, 0.13), 0 0 24px 0 rgba(0, 0, 0, 0.12)',
              borderRadius: '6px',
              maxWidth: maxWidth,
              overflow: 'auto',
              padding: '0',
              position: 'relative',
              width: '100%',
            }}
            ref={contentRef}
          >
            {onCancel && typeof onCancel === 'function' && (
              <button
                type="button"
                style={{
                  alignItems: 'center',
                  appearance: 'none',
                  background: '#fcece7',
                  border: 'none',
                  borderRadius: '100%',
                  color: '#cf2e05',
                  cursor: 'pointer',
                  display: 'flex',
                  height: '3rem',
                  justifyContent: 'center',
                  outline: 'none',
                  padding: '0',
                  position: 'absolute',
                  right: '1.5rem',
                  top: '1.5rem',
                  width: '3rem',
                }}
                ref={cancelRef}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.19173 8.32304C7.11362 8.40115 7.11362 8.52778 7.19173 8.60589L10.5858 12L7.19173 15.3941C7.11362 15.4722 7.11362 15.5989 7.19173 15.677L8.3231 16.8083C8.4012 16.8864 8.52783 16.8864 8.60594 16.8083L12.0001 13.4142L15.3942 16.8083C15.4723 16.8864 15.5989 16.8864 15.677 16.8083L16.8084 15.677C16.8865 15.5989 16.8865 15.4722 16.8084 15.3941L13.4143 12L16.8084 8.60589C16.8865 8.52778 16.8865 8.40115 16.8084 8.32304L15.677 7.19167C15.5989 7.11357 15.4723 7.11357 15.3942 7.19167L12.0001 10.5858L8.60594 7.19167C8.52783 7.11357 8.4012 7.11357 8.3231 7.19167L7.19173 8.32304Z"
                    fill="#cf2e05"
                  />
                </svg>

                <span
                  style={{
                    clip: 'rect(1px, 1px, 1px, 1px)',
                    height: '1px',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '1px',
                  }}
                >
                  Avbryt
                </span>
              </button>
            )}
            {children}
          </div>
        </div>
      ),
      modal,
    )
    : null;
}

export default Modal;
