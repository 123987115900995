import { createModel } from "@rematch/core";
import { IDLE, FETCHING } from "./constants";
import { fetchFrom } from '../utils/fetchData'
import { handleOpeningHours } from '../utils/openingHours'

interface StateT {
  status: StatusT,
  mealStatus: StatusT,
  items: Array<MenuT>,
  meals: MenuMealsT | null
  currentProduct: MealT | null,
  addOns: Array<AddOnT>
}

export type StatusT = "IDLE" | "FETCHING" | "ERROR"

export type MenuT = {
  id: string,
  name: string,
  description?: string, 
  image?: string,
  openingHours: Array<OpeningHoursT>
  restaurantMessage?: {
    title?: string,
    text?: string,
  },
}

export type OpeningHoursT = {
  day: number,
  open: string,
  close: string,
  specialDay: boolean,
  extraInfo?: string,
}

export type MenuMealsT = {
  id: string,
  name: string,
  description?: string,
  restaurantMessage?: {
    title?: string,
    text?: string,
  },
  categories: Array<MealCategoryT>
}

export type MealCategoryT = {
  id: string,
  name: string,
  description?: string,
  products: Array<MealT>
  sortOrder?: number,
}

export type MealT = {
  id: string,
  name: string,
  description?: string,
  price: number,
  image?: string | void,
  nutrientFacts?: string,
  sortOrder?: number,
  extraIngredients?: Array<{
    id: string,
    name: string,
    price: number,
    amount?: number,
    selected: boolean,
  }>,
  customizations: any
}

export type AddOnT = {
  id: string,
  Name: string,
  Type: number,
  description?: string,
  imageUrl?: string,
  orderingNumber?: number,
  products: Array<any>
}

export const menus = createModel()({
  state: {
    status: IDLE,
    mealStatus: IDLE,
    items: [],
    meals: null,
    currentProduct: null,
    addOns: [],
  } as StateT,
  reducers: {
    startFetching(state, payload) {
      return { ...state, status: FETCHING };
    },
    startFetchingMeals(state, payload) {
      return { ...state, mealStatus: FETCHING };
    },
    setMenuItems(state, payload: Array<MenuT>) {
      return { ...state, status: IDLE, items: payload };
    },
    setMeals(state, payload: MenuMealsT) {
      return { ...state, mealStatus: IDLE, meals: payload };
    },
    setProduct(state, payload: MealT | null) {
      return { ...state, status: IDLE, currentProduct: payload };
    },
    setAddons(state, payload: Array<AddOnT>) {
      return { ...state, status: IDLE, addOns: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchMenus(payload, state) {

      const { items } : { items: Array<any>} = state.menus

      if(items.length < 1) {
        const storeId = payload
        try {
          dispatch.menus.startFetching()
          const { restaurants } = await fetchFrom(`/kiosk/${storeId}`)
          
          if(restaurants && restaurants.length > 0) {
            const menus = restaurants.sort((a: any, b: any) => {
              const first = a.orderingNumber ? a.orderingNumber : 10000
              const second = b.orderingNumber ? b.orderingNumber : 10000
              return first - second
            }).map(({ id, name, description, imageUrl, openHours, restaurantMessage }: MenuT & {
              openHours: Array<OpeningHoursT>
              sortOrder?: number,
              imageUrl?: string,
            }) => {
              return {
                id,
                name,
                description,
                image: imageUrl,
                openingHours: openHours,
                restaurantMessage
              }
            }).sort((a: any, b: any) => {
              const aOpeningHours = handleOpeningHours(a.openingHours)
              const bOpeningHours = handleOpeningHours(b.openingHours)

              const first = aOpeningHours.isOpen ? 1 : 0
              const second = bOpeningHours.isOpen ? 1 : 0
              return second - first
            })
            dispatch.menus.setMenuItems(menus)
          } else {
            dispatch.menus.setMenuItems([])
          }
        } catch (error) {
          console.log(error)
          dispatch.menus.setMenuItems([])
        }
      }
    },
    async fetchMealsInMenu(payload, state) {
      const { storeId, restaurantId} = payload
      
      try {
        dispatch.menus.startFetchingMeals()
        const menu = await fetchFrom(`/kiosk/${storeId}/restaurants/${restaurantId}`)
        const meals = await fetchFrom(`/kiosk/${storeId}/restaurants/${restaurantId}/menus`)
        if(menu && meals) {
          const mealsInMenu = {
            id: menu.id,
            name: menu.name,
            description: menu.description,
            restaurantMessage: menu.restaurantMessage,
            categories: meals.map(({ id, name, description, orderingNumber, items }: {
              id: string,
              name: string,
              description: string,
              orderingNumber: number,
              items: Array<any>
            }) => ({
              id,
              name,
              description,
              sortOrder: orderingNumber,
              products: items?.map(meal => ({
                id: meal.id,
                name: meal.name,
                description: meal.description,
                image: meal.imageUrl,
                sortOrder: meal.orderingNumber,
                price: meal.price,
                nutrientFacts: meal.nutrientFacts,
                customizations: {
                  addable: meal.customizations?.addable.reduce((prev: any, current: any) => {
                    return [
                      ...prev,
                      ...current.customizationMenuItems
                    ]
                  }, []),
                  removable: meal.customizations?.removable.reduce((prev: any, current: any) => {
                    return [
                      ...prev,
                      ...current.customizationMenuItems
                    ]
                  }, []),
                  customizable: meal.customizations?.customizable
                },
                extraIngredients: meal.extraIngredients?.map((extra : { 
                  id: string, 
                  displayName: string,
                  price: number,
                  amount: number,
                }) => ({
                  id: extra.id,
                  name: extra.displayName,
                  price: extra.price,
                  amount: extra.amount,
                  selected: false,
                }))
              }))
            }))
          }
          dispatch.menus.setMeals(mealsInMenu)
        } else {
          dispatch.menus.setMeals([])
        }
      } catch (error) {
        console.log(error)
        dispatch.menus.setMeals([])
      }
    },
    async fetchProduct(payload, state) {
      const { menuId, productId } = payload
      try {
        dispatch.menus.startFetching()
        const meal = await fetchFrom(`/menus/${menuId}/menuItems/${productId}`)
     
        if(meal) {
          const product = {
            id: meal.id,
            name: meal.name,
            description: meal.description,
            image: meal.imageUrl,
            sortOrder: meal.orderingNumber,
            price: meal.price,
            nutrientFacts: meal.nutrientFacts,
            customizations: {
              addable: meal.customizations?.addable.reduce((prev: any, current: any) => {
                return [
                  ...prev,
                  ...current.customizationMenuItems
                ]
              }, []),
              removable: meal.customizations?.removable.reduce((prev: any, current: any) => {
                return [
                  ...prev,
                  ...current.customizationMenuItems
                ]
              }, []),
              customizable: meal.customizations?.customizable
            },
            extraIngredients: meal.extraIngredients?.map((extra : { 
              id: string, 
              displayName: string,
              price: number,
              amount: number,
            }) => ({
              id: extra.id,
              name: extra.displayName,
              price: extra.price,
              amount: extra.amount,
              selected: false,
            }))
          }
          dispatch.menus.setProduct(product)
        } else {
          dispatch.menus.setProduct(null)
        }
      } catch (error) {
        console.log(error)
        dispatch.menus.setProduct(null)
      }
    },
    async fetchAddOns(restaurantId, state) {
      try {
        dispatch.menus.startFetching()
        const groups = await fetchFrom(`/restaurants/${restaurantId}/groups`)
        if(groups && groups.length > 0) {
          const groupsSorted = groups.filter(({ products }: AddOnT) => products && products.length > 0).sort((a: AddOnT, b: AddOnT) => {
            const first = a.orderingNumber ? a.orderingNumber : 10000
            const second = b.orderingNumber ? b.orderingNumber : 10000
            return first - second
          }).map((group: AddOnT) => {
            return {
              ...group,
              products: group.products.map((product : any) => ({
                ...product,
                amount: 0,
                itemType: group['Type'] === 5 ? 100 : 200
              })).sort((a, b) => {
                const first = a.orderingNumber ? a.orderingNumber : 10000
                const second = b.orderingNumber ? b.orderingNumber : 10000
                return first - second
              })
            }
          })
          dispatch.menus.setAddons(groupsSorted)
        } else {
          dispatch.menus.setAddons([])
        }
      } catch (error) {
        console.log(error)
        dispatch.menus.setAddons([])
      }
    }
  }),
});
