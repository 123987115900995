import React from 'react'

type MinusT = {
  color?: string,
  className?: any,
}


function Minus({ color = "#3a3a3a", className }: MinusT) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M9 15a.249.249 0 0 0-.223.138l-.75 1.5A.25.25 0 0 0 8.25 17h15.518a.258.258 0 0 0 .259-.259v-1.483a.258.258 0 0 0-.26-.258H9z"/></g></svg>
  )
}

export default Minus