import * as React from 'react';
import { connect } from 'react-redux';
import { handleOpeningHours } from '../../../utils/openingHours';
import { RootState, Dispatch } from '../../../app/store';
import type { MenuT } from '../../../models/cart';
import type { StoreT, StatusT } from '../../../models/store';
import Loading from '../../Loading';
import EatIn from '../../../images/eat';
import TakeAway from '../../../images/takeaway';
import MessageBanner from '../../MessageBanner';
import styles from './Start.module.css';
import { useLocation, useNavigate, useParams } from 'react-router';

const lightModeStartPage = {
  täby: '0594bfa2-9dc1-423d-53a0-08d6d842082e',
  roslagstull: '8ea8885a-0930-4a4b-0bab-08db0f57590e',
  österåker: '6f0038f5-63cc-4dda-d2e9-08db046760bc',
  haninge: 'da42d5da-5abd-487c-656d-08d7cf4ca036'
}

interface StartT {
  fetchStore: (payload: string) => void;
  setMenuTo: (payload: MenuT) => void;
  store: StoreT | null;
  status: StatusT;
}

function Start ({
  fetchStore,
  store,
  status,
  setMenuTo,
}: StartT) {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    logo,
    description,
    openingHours,
    startupMessage,
    backgroundUrl,
  } = store || {};
  const backgroundImg = backgroundUrl
    ? `url(${backgroundUrl})`
    : 'url(/start-bg.jpg)';
  const [storeIsOpen, setStoreIsOpen] = React.useState(true);
  const { openingDetails }: { openingDetails: { opening?: string } } =
    handleOpeningHours(openingHours || []);

  const getStore = React.useCallback(() => {
    storeId && fetchStore(storeId);
  }, [fetchStore, storeId]);

  React.useEffect(() => {
    getStore();
  }, [getStore]);


  const isLight = React.useMemo(() => {
    return Object.values(lightModeStartPage).includes(storeId as string)

  }, [storeId, lightModeStartPage])

  React.useEffect(() => {
    const interval = setInterval(() => {
      const { isOpen } = handleOpeningHours(openingHours || []);
      if (isOpen !== storeIsOpen) {
        setStoreIsOpen(isOpen);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [openingHours, storeIsOpen]);

  function onButtonClick (menuType: MenuT) {
    setMenuTo(menuType);
    navigate(`/butik/${storeId}/restaurant`);
  }

  return status === 'FETCHING' ? (
    <div className={styles.page}>
      <Loading />
    </div>
  ) : storeIsOpen ? (
    <div
      style={{
        backgroundImage: backgroundImg,
      }}
      className={styles.page}
    >
      <div className={styles.content}>
        {logo && (
          <a className={styles.link} href={location.pathname}>
            <img className={styles.logo} src={!isLight && storeId !== lightModeStartPage.haninge ? logo : logo.replace('upload/', 'upload/e_bgremoval/')} alt="Restaurangens logo" />
          </a>
        )}
        {/* TODO: Change to dynamic theme */}
        {isLight ? (
          <>
            <h1 className={styles.headingLight}>Gör din beställning här</h1>
            <p className={styles.preambleLight}>{description}</p>
          </>
        ) : (
          <>
            <h1 className={styles.heading}>Gör din beställning här</h1>
            <p className={styles.preamble}>{description}</p>
          </>
        )}

        <div className={styles.actions}>
          <div className={styles.actionCard}>
            <div
              className={
                startupMessage
                  ? styles.actionContentSmall
                  : styles.actionContent
              }
            >
              <button
                className={styles.actionButton}
                type="button"
                onClick={() => onButtonClick('EATIN')}
              >
                <EatIn
                  className={startupMessage ? styles.iconSmall : styles.icon}
                />
                <span
                  className={
                    startupMessage ? styles.actionTextSmall : styles.actionText
                  }
                >
                  Äta här
                </span>
              </button>
            </div>
          </div>
          <div className={styles.actionCard}>
            <div
              className={
                startupMessage
                  ? styles.actionContentSmall
                  : styles.actionContent
              }
            >
              <button
                className={styles.actionButton}
                type="button"
                onClick={() => onButtonClick('TOGO')}
              >
                <TakeAway
                  className={startupMessage ? styles.iconSmall : styles.icon}
                />
                <span
                  className={
                    startupMessage ? styles.actionTextSmall : styles.actionText
                  }
                >
                  Ta med
                </span>
              </button>
            </div>
          </div>
        </div>
        {startupMessage && (
          <MessageBanner
            title={startupMessage.title}
            description={startupMessage.text}
            maxWidth={'585px'}
          />
        )}
      </div>
    </div>
  ) : (
    <div className={styles.closedPage}>
      <EatIn className={styles.closedIcon} />
      <h1 className={styles.closedHeading}>
        Köket öppnar {openingDetails.opening}
      </h1>
      {openingDetails?.opening && (
        <p className={styles.closedInfo}>Välkommen då!</p>
      )}
    </div>
  );
}

function mapState (state: RootState) {
  return {
    status: state.store.status,
    store: state.store.current,
  };
}

function mapDispatch (dispatch: Dispatch) {
  return {
    setMenuTo: dispatch.cart.selectMenuType,
    fetchStore: dispatch.store.fetchStore,
  };
}

export default connect(mapState, mapDispatch)(Start);
