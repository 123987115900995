import * as React from 'react'
import type { MenuT } from '../models/menus'
import { Link }  from 'react-router-dom'
import Angle from '../icons/Angle'
import styles from './Carousel.module.css'

type CarouselT = {
  items: Array<MenuT>
  isActiveMenu: (navPath: string) => boolean,
  storeId: string,
}

function Carousel({ items, isActiveMenu, storeId }: CarouselT) {
  const navItems = React.useRef<HTMLDivElement>(null)
  const [showNavButtons, setShowNavButtons] = React.useState(true)
  const [slideIndex, setSlideIndex] = React.useState(0)
  const [maxIndex, setMaxIndex] = React.useState(0)

  React.useLayoutEffect(() => {
    const width = navItems.current?.offsetWidth || 0
    const numberOfVisibleItems = Math.floor(width / 185)
    
    if(numberOfVisibleItems < items.length) {
      setMaxIndex(items.length - numberOfVisibleItems)
      setShowNavButtons(true)
    } else {
      setShowNavButtons(false)
    }
  }, [items])

  function onPressNext() {
    if(slideIndex < maxIndex) {
      setSlideIndex(prev => prev + 1)
    }
  }

  return (
    <>
      {showNavButtons && (
        <div className={styles.prevButtonContainer}>
          <button onClick={() => setSlideIndex(prev => prev - 1)} disabled={slideIndex <= 0} type="button" className={styles.prevButton}>
            <span className={styles.hidden}>Föregående</span>
            <Angle color={slideIndex <= 0 ? '#bdbbb9' : '#cf2e05'} className={styles.buttonIcon} direction="left" />
          </button>
        </div>
      )}
      <div ref={navItems} className={showNavButtons ? styles.navItemsWithButtons : styles.navItems}>
        <div style={{
          transform: `translateX(-${slideIndex * 201}px)`
        }} className={styles.items}>
          {items.map(({ id, name}: MenuT) => (
            <Link  key={id} className={isActiveMenu(`/butik/${storeId}/restaurant/${id}`) ? styles.activeMenuButton : styles.menuButton} to={`/butik/${storeId}/restaurant/${id}`}>{name}</Link>
          ))}
        </div>
      </div>
      {showNavButtons && (
      <div className={styles.nextButtonContainer}>
        <button onClick={onPressNext} disabled={slideIndex === maxIndex} type="button" className={styles.nextButton}>
            <span className={styles.hidden}>Nästa</span>
            <Angle color={slideIndex === maxIndex ? '#bdbbb9' : '#cf2e05'} className={styles.buttonIcon} direction="right" />
        </button>
      </div>
      )}
    </>
  )
}

export default Carousel