import { createModel } from "@rematch/core";
import { IDLE, FETCHING } from "./constants";
import { fetchFrom } from '../utils/fetchData'
import { groupedItems } from "../utils/groupedItems";
import { Receipt } from "../types/Receipt";

interface StateT {
  status: StatusT,
  swishToken: string | null,
  paymentUrl: string | null
  paymentStatus: number,
  currentOrderId: string | null,
  currentOrder: any,
  receipt: any,
}

export type StatusT = "IDLE" | "FETCHING" | "ERROR"

export const order = createModel()({
  state: {
    status: IDLE,
    swishToken: null,
    paymentUrl: null,
    paymentStatus: 0,
    currentOrderId: null,
    currentOrder: {},
  } as StateT,
  reducers: {
    startFetching (state, payload) {
      return { ...state, status: FETCHING };
    },
    setSwishToken (state, payload: string) {
      return { ...state, swishToken: payload };
    },
    setPaymentUrl (state, payload: string) {
      return { ...state, paymentUrl: payload };
    },
    setPaymentStatus (state, payload: number) {
      return { ...state, paymentStatus: payload };
    },
    setCurrentOrderId (state, payload: string) {
      return { ...state, currentOrderId: payload };
    },
    setCurrentOrder (state, payload: any) {
      return { ...state, currentOrder: payload };
    },
    setReceipt (state, payload: any) {
      return { ...state, receipt: payload };
    },
  },
  effects: (dispatch) => ({
    async createOrder (payload, state: any) {

      const { cartId }: { cartId: string | null } = state.cart

      try {
        dispatch.order.startFetching()
        const purchaseEndpoint = '/svc-checkout/v1/purchase'


        if (cartId) {
          const { orderId, operations } = await fetchFrom(purchaseEndpoint, {
            method: 'POST',
            body: {
              cartId
            }
          })

          const [paymentOption] = operations

          if (paymentOption) {
            const paymentUrl = paymentOption.href
            if (paymentUrl) {
              dispatch.order.setPaymentUrl(paymentUrl)
              dispatch.order.setCurrentOrderId(orderId)
            }
          } else if (paymentOption) {
            const queryString = paymentOption.href.split('?')
            if (queryString && queryString.length > 0) {
              const queries = queryString[1].split('&')
              const [token] = queries.filter((item: string) => item.includes('token=')).map((tokenQuery: string) => {
                return tokenQuery.split('=')[1]
              })
              if (token) {
                dispatch.order.setSwishToken(token)
                dispatch.order.setCurrentOrderId(orderId)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getPaymentStatus (payload, state) {
      const { storeId } = payload
      const { currentOrderId }: { currentOrderId: string | null } = state.order
      const orderId = currentOrderId
      try {
        const order = await fetchFrom(`/order/${orderId}/${storeId}`, {
          method: 'GET',
        })
        if (order) {
          dispatch.order.setCurrentOrder(order)
          const { paymentStatus } = order.paymentInformation || {}
          if (paymentStatus) {
            dispatch.order.setPaymentStatus(paymentStatus)
          }
        }
      } catch (error) {
        console.log(error)
      }

    },
    async getReceipt (payload, state) {
      const { storeId, orderId }: { storeId: string, orderId?: string } = payload
      let currentOrder;
      if (orderId) {
        currentOrder = orderId
      } else {
        const { currentOrderId }: { currentOrderId: string | null } = state.order
        currentOrder = currentOrderId
      }
      if (currentOrder) {
        try {
          const receipt: Receipt = await fetchFrom(`/order/${currentOrder}/${storeId}/receipt`, {
            method: 'GET',
          })
          const receiptWithGroupedItems = receipt && groupedItems(receipt)
          dispatch.order.setReceipt(receiptWithGroupedItems)
        } catch (error) {
          console.log(error)
        }
      }
    },
    async verifyPayment (payload, state) {
      const { storeId, orderId } = payload || {}
      try {
        const verified = await fetchFrom(`/svc-checkout/v1/verifypayment/store/${storeId}/order/${orderId}`, {
          method: 'GET',
        })
        if (verified?.status) {
          dispatch.order.setPaymentStatus(verified?.status)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }),
});
