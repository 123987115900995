import React from 'react'

type ArrowT = {
  direction: string,
  color?: string,
  className?: any,
}


function Arrow({ direction, color = "#3a3a3a", className }: ArrowT) {
  return direction === 'left' ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M8.742 15.001l7.17-6a.25.25 0 0 0 .029-.352l-.969-1.146a.25.25 0 0 0-.352-.029l-9.266 7.762c-.472.4-.472 1.13 0 1.53l9.266 7.762a.25.25 0 0 0 .352-.029l.969-1.145a.251.251 0 0 0-.029-.353l-7.17-6H26.75a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25H8.742z"/></g></svg>
  ) : direction === 'right' ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M17.381 7.474a.25.25 0 0 0-.352.03L16.06 8.65a.25.25 0 0 0 .029.352l7.169 6H5.25a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h18.008l-7.169 6a.25.25 0 0 0-.029.352l.969 1.146a.25.25 0 0 0 .352.029l9.265-7.763c.473-.4.473-1.129 0-1.529l-9.265-7.763z"/></g></svg>
  ) : null
}

export default Arrow