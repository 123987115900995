import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import type { RootState, Dispatch } from '../../app/store';
import type { MenuT, ItemT } from '../../models/cart';
import type { StatusT } from '../../models/order';
import { getFormattedPrice } from '../../utils/formattedPrice';
import QuantityButton from '../QuantityButton';
import styles from './Cart.module.css';

type CartT = {
  status: StatusT;
  cartItems: Array<ItemT>;
  menuType?: MenuT;
  totalPrice: number;
  fetchCart: (payload?: any) => Promise<void>;
  createOrder: (payload?: any) => Promise<void>;
  removeItemFromCart: (cartItemId: string) => Promise<void>;
  updateItemQuantity: (payload: any) => Promise<void>
};

function Cart ({
  status,
  menuType,
  cartItems,
  totalPrice,
  fetchCart,
  removeItemFromCart,
  createOrder,
  updateItemQuantity
}: CartT) {
  const navigate = useNavigate();
  const { storeId } = useParams<any>();

  React.useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  function getMenuTypeText () {
    switch (menuType) {
      case 'EATIN':
        return 'Äta här';
      case 'TOGO':
        return 'Ta med';
      default:
        return 'Äta här';
    }
  }

  function getNumberOfArticlesText () {
    const numberOfItems = cartItems.reduce((prev: number, current: ItemT) => {
      return prev + current.quantity
    }, 0)
    if (numberOfItems === 1) {
      return `${numberOfItems} Artikel`;
    } else {
      return `${numberOfItems} Artiklar`;
    }
  }

  async function onRemoveItem (cartItemId: string) {
    await removeItemFromCart(cartItemId);
  }

  async function handleChange (cartItemId: string, newQuantity: number) {
    if (newQuantity === 0) {
      await onRemoveItem(cartItemId)
    } else {
      await updateItemQuantity({ storeId, cartItemId, quantity: newQuantity, })
    }
  }

  async function onCheckout () {
    createOrder();
    storeId && navigate(`/butik/${storeId}/stammis`);
  }

  return (
    <div className={styles.cart}>
      <h3 className={styles.heading}>Din beställning – {getMenuTypeText()}</h3>
      <div className={styles.content}>
        <div className={styles.cartItems}>
          {cartItems && cartItems.length > 0 ? (
            <ul>
              {cartItems.map((item) => (
                <li className={styles.cartItem} key={item.id}>
                  <div className={styles.itemRow}>
                    <div className={styles.cartItemInfo}>
                      <span className={styles.productName}>{item.name}</span>
                      <span>{getFormattedPrice(item.price * item.quantity)}</span>
                    </div>
                    {item.customizationMenuItems &&
                      item.customizationMenuItems.length > 0 &&
                      item.customizationMenuItems.map((ingredient) => {
                        if (!ingredient.preSelected || ingredient.price !== 0) {
                          return (
                            <div
                              key={ingredient.id}
                              className={styles.extraIngredientRow}
                            >
                              <span className={styles.extraIngredient}>
                                {ingredient.customizationType === 1
                                  ? '+'
                                  : ingredient.customizationType === 2
                                    ? '-'
                                    : ''}{' '}
                                {ingredient.name}
                              </span>
                              <span className={styles.extraIngredientPrice}>
                                {getFormattedPrice(ingredient.price * item.quantity)}
                              </span>
                            </div>
                          )
                        }
                      })}
                  </div>
                  <div className={styles.remove}>
                    <QuantityButton quantity={item.quantity} onDecrease={() => handleChange(item.id, item.quantity - 1)} onIncrease={() => handleChange(item.id, item.quantity + 1)} type='cart' />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={styles.emptyCart}>
              <p className={styles.emptyCartText}>
                Din beställning kommer synas här
              </p>
            </div>
          )}
        </div>
        <div className={styles.checkout}>
          <div className={styles.totalArticles}>
            <span>{getNumberOfArticlesText()}</span>
          </div>
          <div className={styles.total}>
            <span>Att betala</span>
            <span>{getFormattedPrice(totalPrice)}</span>
          </div>
          <button
            disabled={cartItems.length < 1 || status === 'FETCHING'}
            onClick={onCheckout}
            className={styles.checkoutButton}
            type="button"
          >
            {status === 'FETCHING' ? <>Laddar…</> : <>Betala</>}
          </button>
        </div>
      </div>
    </div >
  );
}

function mapState (state: RootState) {
  return {
    status: state.order.status,
    cartItems: state.cart.items,
    menuType: state.cart.menuType,
    totalPrice: state.cart.totalPrice,
  };
}

function mapDispatch (dispatch: Dispatch) {
  return {
    fetchCart: dispatch.cart.fetchCart,
    removeItemFromCart: dispatch.cart.removeItemFromCart,
    createOrder: dispatch.order.createOrder,
    updateItemQuantity: dispatch.cart.updateItemQuantity
  };
}

export default connect(mapState, mapDispatch)(Cart);
