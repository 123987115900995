
import React from 'react'
import Plus from '../icons/Plus'
import Minus from '../icons/Minus'
import styles from './QuantityButton.module.css'


type QuantityButtonT = {
  onDecrease: () => void,
  onIncrease: () => void,
  quantity: number
  type?: 'cart' | 'addon' | undefined
}

function QuantityButton ({ onDecrease, onIncrease, quantity, type }: QuantityButtonT) {
  return (
    <div className={styles.quantityButton}>
      <button className={quantity > 0 ? styles.activeDecreaseButton : styles.decreaseButton} type="button" onClick={onDecrease}>
        <Minus color={quantity > 0 ? "#cf2e05" : "#bdbbb9"} className={styles.icon} />
      </button>
      <span className={quantity > 0 && type !== 'cart' ? styles.activeQuantity : styles.quantity}>{quantity}</span>
      <button className={styles.increaseButton} type="button" onClick={onIncrease}>
        <Plus color="#fff" className={styles.icon} />
      </button>
    </div>
  )
}
export default QuantityButton