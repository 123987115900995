import React from 'react';
import { connect } from 'react-redux';
import type { RootState, Dispatch } from '../../../app/store';
import styles from './MobilePayment.module.css';
import { useParams } from 'react-router';

function Thanks ({ getReceipt, receipt }: any) {
  const { storeId, orderId } = useParams()

  React.useEffect(() => {
    getReceipt({ storeId, orderId });
  }, [storeId, orderId, getReceipt]);

  return (
    <div className={styles.thanks}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Tack för ditt köp!</h1>
        {receipt ? (
          <div>
            <p className={styles.orderLabel}>Ditt ordernummer</p>
            <span className={styles.orderNo}>{receipt?.order?.loopNumber}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function mapState (state: RootState) {
  return {
    receipt: state.order.receipt,
  };
}
function mapDispatch (dispatch: Dispatch) {
  return {
    getReceipt: dispatch.order.getReceipt,
  };
}

export default connect(mapState, mapDispatch)(Thanks);
