import * as React from 'react'
import { Link } from 'react-router-dom'
import styles from './Item.module.css'

type MenuItemT = {
  href: string,
  name: string,
  description?: string,
  openingDetails: {
    opening?: string,
    closes?: string,
  },
  isOpen: boolean,
  image?: string,
  message?: {
    title?: string,
    text?: string,
  }
}

function MenuItem ({ href, name, description, image, isOpen, openingDetails, message }: MenuItemT) {
  const { opening, closes } = openingDetails

  let backgroundImage = {}
  if (image) {
    backgroundImage = {
      backgroundImage: `url(${image})`,
      WebkitMaskImage: "url(/mask-image.svg)",
      maskImage: "url(/mask-image.svg)"
    }
  }
  return (
    <li className={styles.item}>
      <Link className={styles.link} to={href}>
        <div className={styles.image} style={backgroundImage}>
          {isOpen === false && (
            <div className={styles.closedOverlay}>
              <div className={styles.closed}>
                <span className={styles.closedHeading}>Öppnar {opening}</span>
                <span className={styles.closedInfo}>Välkommen då!</span>
              </div>
            </div>
          )}
          {isOpen && message && (
            <div className={styles.messageOverlay}>
              <div className={styles.message}>
                <span className={styles.messageHeading}>{message.title}</span>
                <span className={styles.messageInfo}>{message.text}</span>
              </div>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div>
            <h4 className={styles.heading}>{name}</h4>
            {description && (
              <p className={styles.description}>{description}</p>
            )}
          </div>
          {opening && closes && (
            <span className={isOpen ? styles.openingHoursActive : styles.openingHours}>{
              `Öppet: ${opening} – ${closes}`
            }</span>
          )}
        </div>
      </Link>
    </li>
  )
}
export default MenuItem